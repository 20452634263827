import { takeLatest, put, all, call, select, takeEvery } from 'redux-saga/effects';
import { getProducts } from '../../resources/Api';
import ACTIONS from './actions';
import APPACTIONS from '../app.actions';

const getLang = state => state.i18n.lang;

function* fetchStartAsync() {
    try {
        const res = yield getProducts();
        yield put({ type: ACTIONS.FETCH_SUCCESS, payload: res });
        yield call(translate);
    } catch (e) {
        console.error(e)
        yield put({ type: ACTIONS.FETCH_ERROR });
    }
}

function* fetchStart() {
    yield takeLatest(ACTIONS.FETCH_START, fetchStartAsync);
}


function* translate() {
    try {
        const lang = yield select(getLang);
        yield put({ type: ACTIONS.TRANSLATE, payload: lang })
    } catch (error) {
        console.error(error)
    }
}

export default function* sagas() {
    yield all([
        call(fetchStart),
        takeEvery(APPACTIONS.TRANSLATE, translate)
    ]);
}
