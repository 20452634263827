import React from "react";
import styled from "styled-components";
import { LIGHT_BLUE } from "../../../../../resources/Colors";

const Container = styled.div`
  background-color: ${({ background }) =>
    background ? `${background}` : `${LIGHT_BLUE}`};
  padding: 0.3em 1em;
  p {
    margin: 5px 0px;
  }
  position: relative;
  margin-top: 0.5rem;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid ${({ background }) => background ? `${background}` : `${LIGHT_BLUE}`};
  position: absolute;
  top: -15px;
  right: 4.3rem;
`;

const Message = ({ message }) =>
  !!message ? (
    <Container background={message.color}>
      <Triangle background={message.color}/>
      <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: message.title }} />
    </Container>
  ) : (
    ""
  );

export default Message;
