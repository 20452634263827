export const LIGHT = "#3BAED0";
export const DARK = "#364357";
export const PRIMARY = "#374458";
export const RED = "#FB0606";
export const GRAY = "#f0f0f0";
export const OPAQUE_TEXT = "#707070";
export const LIGHT_BLUE = "#4990E2";
export const LIGHT_GRAY = "#ebebeb";
export const LIGHT_GRAY_ALT = "#FBFBFB";
export const BLUE = "#388CFF";
export const STRONG_BLUE = "#364456";
export const BORDER_BLUE = "#4990E2";