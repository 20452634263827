import Actions from './actions';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case Actions.FETCH_SUCCESS:
            return { ...action.payload };

        default:
            return state;
    }
};
