import React, { createContext, useEffect } from "react";
import { useState } from "react";

export const ViewContext = createContext();

export const ViewContextProvider = ({ children }) => {
  const [viewList, setViewList] = useState(new Map());

  const toggleView = (id)=>{
    const value = viewList.get(id);
    setViewList(new Map(viewList.set(id, !value)));
  };

  const setView = (id, value)=>{
    setViewList(new Map(viewList.set(id, value)));
  };

  const getView = (id)=>viewList.get(id);

  return (
    <ViewContext.Provider value={{getView, toggleView, setView}}>
      {children}
    </ViewContext.Provider>
  );
};
