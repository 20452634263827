import ACTIONS from './actions'
import es from './es';
import en from './en';
import { DEFAULT_LANG } from 'resources/constants';

const initialLang = localStorage.getItem('lang-var') ?? DEFAULT_LANG;

const initialState = {
    lang: initialLang ? +initialLang : 0,
    texts: initialLang == 0 ? es : en
};


const dictionaries = [es, en];

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.TRANSLATE: {
            localStorage.setItem('lang-var', action.payload)
            
            return { lang: action.payload, texts: dictionaries[action.payload] }
        }

        default: return state;
    }
}