import React, { useMemo, useState } from "react";
import styled from 'styled-components';
import ProductFull from './ProductFull';
import ProductSimple from './ProductSimple';
import ProductDialog from "components/ProductDialog";

import useProducts from "state/products";
import useOrder from "state/order";
import VariantSelectionDialog from "components/VariantSelectionDialog";

const Layout = styled.div`
  position: relative;
`;

const Container = styled.div`
  opacity: ${({ active }) => (active ? '1' : '0.93')};
`;

export const Product = (props) => {
  const [, , utils] = useProducts();
  const [order, orderDispatcher] = useOrder();
  const { stockTotal, status, img, description, productId, variants } = props;
  const producType = !!description || !!img;
  const is86Inactive = ((status === 'active' && stockTotal === 0) ? true : false);

  const [openProductDialog, setOpenProductDialog] = useState(!!productId);

  const handleOrder = (id, count) => orderDispatcher.add(id, count);
  const removeOrder = (id) => orderDispatcher.remove(id);

  const closeProductDialog = () => {
    setOpenProductDialog(false);
  };

  const handleOpenProductDialog = (product) => {
    setSelectedProduct(product);
    setOpenProductDialog(true);
  };

  const [openVariantDialog, setOpenVariantDialog] = useState(false);

  const closeVariantDialog = () => {
    setOpenVariantDialog(false);
  }

  const handleOpenVariantDialog = (product) => {
    setSelectedProduct(product);
    setOpenVariantDialog(true);
  }

  const [selectedProduct, setSelectedProduct] = useState(
    productId !== undefined ? utils.find([{ id: productId }])[0] : {}
  );

  const handleSelection = (variantId, remove) => {
    if (remove) {
      return removeOrder(variantId);
    }

    if (variants.length < 2) {
      return handleOrder(variantId);
    }

    return handleOpenVariantDialog(props);
  }

  const handleMultipleOrders = (order) => {
    Object.keys(order).forEach((variantId) => {
      if (!order[variantId]) {
        return
      }
      order[variantId] > 1 ?
        handleOrder(variantId, order[variantId]) :
        handleOrder(variantId);
    });
  };

  const { amounts, isOrdered } = useMemo(() => {
    const inOrder = order.filter(o => variants?.some(v => +v.id === +o.id))
    return {
      amounts: inOrder,
      isOrdered: !!inOrder
    }
  }, [orderDispatcher, orderDispatcher.orders]);

  return (
    <Layout>
      <Container active={!!stockTotal && status === 'active'}>
        {producType
          ? <ProductFull
            handleCard={() => handleOpenProductDialog(props)}
            handleSelection={handleSelection}
            is86Inactive={is86Inactive}
            isOrdered={isOrdered}
            amounts={amounts}
            {...props}
          />
          : <ProductSimple is86Inactive={is86Inactive} isOrdered={isOrdered} handleSelection={handleSelection} amounts={amounts} {...props} />
        }
      </Container>
      <ProductDialog
        open={openProductDialog}
        handleClose={() => closeProductDialog()}
        product={selectedProduct}
        handleMultipleOrders={handleMultipleOrders}
      />
      <VariantSelectionDialog
        open={openVariantDialog}
        handleClose={() => closeVariantDialog()}
        product={props}
        handleMultipleOrders={handleMultipleOrders}
      />
    </Layout>
  );
};

export default Product;
