import React, { useState, useMemo } from "react";
import Modal from "react-modal";
import Slide from "@material-ui/core/Slide";
import styled from "styled-components";
import { loadFromLocalStorage, setOnLocalStorage } from "state/utils";
import { CONFIG_LAST_UPDATED } from "resources/constants";
import Promotion from "./Promotion";
import useConfig from "state/config";
import CloseSVG from "resources/img/close-Icon.svg";
import "./styles.css";
import { useEffect } from "react";
import { Button, ButtonLabel, Title } from "./tutorial-styles";
import useI18n from "state/i18n";

Modal.setAppElement("#root");

const MILLIS_IN_HOUR = 1000 * 60 * 60;

const CloseIcon = styled.img`
  position: absolute;
  right: 6px;
  top: -27px;
  width: 20px;
  cursor: pointer;
  z-index: 5;
`;

const PromotionContent = styled.div`
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  overflow: hidden;
`;

const PickerContent = styled.div`
  padding: 15px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  overflow: hidden;
  background-color: white;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getLastUpdated = (lastUpdated) => (!!lastUpdated ? lastUpdated : 0);

const shouldBeOpened = () => {
  const lastUpdated = loadFromLocalStorage(CONFIG_LAST_UPDATED);
  return Date.now() > getLastUpdated(lastUpdated) + MILLIS_IN_HOUR * 12;
};

export default function ConfigDialog() {
  const [alreadyOpenedOnce, setAlreadyOpenedOnce] = useState(false);
  const [isLocationPickerOpen, setLocationPickerOpen] = useState(false);
  const [isMenuPickerOpen, setMenuPickerOpen] = useState(false);
  const [isPromoOpen, setPromoOpen] = useState(false);
  const [config] = useConfig();
  const [i18n] = useI18n();

  const locationPickerConfig = useMemo(()=>config["location-picker"]?.payload, [config]);
  const menuPickerConfig = useMemo(()=>config["menu-picker"]?.payload, [config]);
  const promotionConfig = useMemo(()=>config.promotion?.payload, [config]);

  const locationPickerEnabled = useMemo(() => {
    if (!locationPickerConfig?.active) return false;
    return true;
  }, [locationPickerConfig]);

  const menuPickerEnabled = useMemo(() => {
    if (!menuPickerConfig?.active) return false;
    return true;
  }, [menuPickerConfig]);

  const promoEnabled = useMemo(() => {
    if (!promotionConfig?.active) return false;
    return shouldBeOpened();
  }, [promotionConfig]);

  function handleInitialModal(locationPickerEnabled, menuPickerEnabled, promoEnabled) {
    if (locationPickerEnabled) {
      setLocationPickerOpen(true);
    } else if (menuPickerEnabled) {
      setMenuPickerOpen(true);
    } else if (promoEnabled) {
      setPromoOpen(true);
    }
  }

  useEffect(() => {
    if (alreadyOpenedOnce) return;
    handleInitialModal(locationPickerEnabled, menuPickerEnabled, promoEnabled);
  }, [locationPickerEnabled, menuPickerEnabled, promoEnabled, alreadyOpenedOnce]);

  function closeLocationPicker() {
    setLocationPickerOpen(false);
    if (menuPickerEnabled) {
      setMenuPickerOpen(true);
      return;
    }
    if (promoEnabled) {
      setPromoOpen(true);
      return;
    }
    setAlreadyOpenedOnce(true);
  }

  function closeMenuPicker() {
    setMenuPickerOpen(false);
    if (promoEnabled) {
      setPromoOpen(true);
      return;
    }
    setAlreadyOpenedOnce(true);
  }

  function closePromo() {
    setPromoOpen(false);
    setOnLocalStorage(CONFIG_LAST_UPDATED, Date.now());
    setAlreadyOpenedOnce(true);
  }

  function handleExternalMenu() {
    if(!!menuPickerConfig.externalMenuURL) {
      window.location.href = menuPickerConfig.externalMenuURL;
      return;
    }
    closeMenuPicker();
  }

  function handleLocationChange(url) {
    if(!!url) {
      window.location.href = url;
      return;
    }
    closeLocationPicker();
  }

  return (
    <>
      {locationPickerEnabled && (
        <Modal
          isOpen={isLocationPickerOpen}
          onRequestClose={closeLocationPicker}
          className={"ui-modal"}
          overlayClassName={"ui-background"}
          shouldCloseOnOverlayClick={true}
        >
          <CloseIcon src={CloseSVG} alt="close" onClick={closeLocationPicker} />
          <PickerContent>
            <div style={{ maxWidth: "80vw", minWidth: "16em", display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch', gap: '10px' }}>
              <Title>{!!i18n.lang ? locationPickerConfig.title.en : locationPickerConfig.title.es}</Title>
              {locationPickerConfig.locations.map(({title, url}) => 
                <Button type="button" onClick={()=>handleLocationChange(url)}>
                  <ButtonLabel dangerouslySetInnerHTML={{ __html: !!i18n.lang ? title.en : title.es }} />
                </Button>
              )}
            </div>
          </PickerContent>
        </Modal>
      )}
      {menuPickerEnabled && (
        <Modal
          isOpen={isMenuPickerOpen}
          onRequestClose={closeMenuPicker}
          className={"ui-modal"}
          overlayClassName={"ui-background"}
          shouldCloseOnOverlayClick={true}
        >
          <CloseIcon src={CloseSVG} alt="close" onClick={closeMenuPicker} />
          <PickerContent>
            <div style={{ maxWidth: "80vw", minWidth: "16em", display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch', gap: '10px' }}>
              <Title>{!!i18n.lang ? menuPickerConfig.title.en : menuPickerConfig.title.es}</Title>
              <Button type="button" onClick={closeMenuPicker}>
                <ButtonLabel>
                  {i18n.tag("DINE_IN")}
                </ButtonLabel>
              </Button>
              <Button type="button" onClick={handleExternalMenu}>
                <ButtonLabel dangerouslySetInnerHTML={{ __html: !!i18n.lang ? menuPickerConfig.externalMenuName.en : menuPickerConfig.externalMenuName.es }} />
              </Button>
            </div>
          </PickerContent>
        </Modal>
      )}
      {promoEnabled && (
        <Modal
          isOpen={isPromoOpen}
          onRequestClose={closePromo}
          className={"ui-modal"}
          overlayClassName={"ui-background"}
          shouldCloseOnOverlayClick={true}
        >
          <CloseIcon src={CloseSVG} alt="close" onClick={closePromo} />
          <PromotionContent backgroundColor={promotionConfig?.backgroundColor}>
            <div style={{ maxWidth: "80vw", minWidth: "16em", height: "70vh", display: "flex" }}>
              <Promotion data={promotionConfig} handleClose={closePromo} />
            </div>
          </PromotionContent>
        </Modal>
      )}
    </>
  );
}
