export const pipe = (...fns) => (x) => fns.reduce((y, f) => f(y), x);
export const formatString = (string) =>
  string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

const formatPathString = (title) =>
  title
    .toLowerCase()
    .split(/ |(\/)/)
    .filter((character) => character !== "/" && character !== undefined)
    .join("-");

export const getParamId = (param) => +param?.split("-")[0];

export const getRouteWithParams = (root, ...params) =>
  `${root}/${params.reduce(
    (acc, curr) => (acc += `${curr.id}-${formatPathString(curr.title)}/`),
    ""
  )}`;
