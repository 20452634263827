import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import BackHeader from "components/BackHeader";
import Product from "components/Product";
import FloatButton from "components/SelectionsFloatButton";
import { getParamId, getRouteWithParams } from "resources/helpers";
import useI18n from "state/i18n";
import useProducts from "state/products";
import {
  SLIDE_IN_RIGHT,
  SLIDE_IN_UP,
  SLIDE_OUT_DOWN,
  SLIDE_OUT_RIGHT,
  Transition,
  WithTransition,
} from "styles/transitions/transitions";
import { FiltersContext } from "pages/Menu/FiltersProvider";
import Categories from "./components/Categories";
import { Selections } from "./components/Selections";
import SubcategoriesSelector from "./components/SubcategoriesSelector";
import Filters from "../containers/Filters";
import Lang from "components/Lang";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopWrapper = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
`;

const PaddingWrapper = styled.div`
  padding: 0 1rem;
`;

const MainContent = styled.main`
  padding: 0 1rem;
  background-color: white;
`;

const ScrollLockContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
`;
const CustomLang = styled(Lang)`
  & > div > div {
    width: unset;
  }
  & > ul {
    left: unset;
    right: 0;
  }
`;

const getCategoriesById = (categories, id) =>
  !!id ? categories.find((category) => category.id === +id) : categories[0];

const ProductsPage = () => {
  const [, , utils] = useProducts();
  const history = useHistory();
  const [isSelectorShown, setIsSelectorShown] = useState(false);
  const { tagsSelected, setTags } = useContext(FiltersContext);
  const [i18n, , lang] = useI18n();
  const {
    categoryId: categoryPath,
    subcategoryId: subcategoryPath,
    productId: productPath,
  } = useParams();
  let categoryId = getParamId(categoryPath);
  let subcategoryId = getParamId(subcategoryPath);

  const category = getCategoriesById(
    utils.getCategoriesWithActiveProducts(),
    categoryId
  );

  useEffect(() => {
    if (!tagsSelected.get(categoryId) || tagsSelected.get(categoryId).length === 0) {
      setTags(categoryId, utils.getDefaultTags(categoryId));
    }
  }, [categoryId]);

  const [allActiveSubcategories, setAllActiveSubcategories] = useState(utils.getSubcategoriesWithActiveProducts(category.id));

  useEffect(() => {
    const category = getCategoriesById(
      utils.getCategoriesWithActiveProducts(),
      categoryId
    )
    setAllActiveSubcategories(utils.getSubcategoriesWithActiveProducts(category.id));
  }, [categoryId, lang]);

  const [subcategories, setSubcategories] = useState(allActiveSubcategories);

  const [subcategorySelected, setSubcategorySelected] = useState(
    !!subcategoryId ? subcategoryId : allActiveSubcategories[0].id
  );

  useEffect(() => {
    setSubcategories(allActiveSubcategories);
  }, [allActiveSubcategories]);

  const setSubcategory = (subcategory) => {
    subcategoryId = subcategory.id;

    window.history.replaceState(
      null,
      "",
      getRouteWithParams("/menu", category, subcategory)
    );

    setSubcategorySelected(subcategory.id);
  };

  const getSubcategorySelected = () => subcategories.find((sc) => sc.id === subcategorySelected);

  const backToHome = () =>
    history.goBack();

  useEffect(() => {
    let subcategoriesFiltered = [];

    if (tagsSelected.get(category.id)?.length > 0) {
      subcategoriesFiltered = allActiveSubcategories.map((subcategory) => ({
        ...subcategory,
        products: subcategory.products.filter((product) =>
          product.tags.some(
            (tag) =>
              tagsSelected.get(category.id).some((tagSelected) => tagSelected.id === tag.id)
          )
        ),
      }));
    } else {
      subcategoriesFiltered = utils.getSubcategoriesWithActiveProducts(
        category.id
      );
    }

    const scs = subcategoriesFiltered.filter((sc) => sc.products.length > 0).map((sc) => ({
      ...sc,
      productsLength: sc.products.length,
    }));

    if (scs.length > 0) {
      if (!scs.some((sc) => sc.id === subcategorySelected)) {
        setSubcategory(scs[0]);
      }
      setSubcategories(scs);
    }
  }, [tagsSelected, allActiveSubcategories]);

  return (
    <WithTransition enter={SLIDE_IN_RIGHT} exit={SLIDE_OUT_RIGHT}>
      <Container>
        <ScrollLockContainer>
          <TopWrapper>
            <BackHeader label={i18n.tag("FULL_MENU")} handler={backToHome} />
            <CustomLang />
          </TopWrapper>

          <PaddingWrapper>
            <Categories handler={() => setIsSelectorShown(true)} title={category.title} />
          </PaddingWrapper>

          <PaddingWrapper>
            <Filters
              tags={utils.getTagsInCategory(category.id)}
              type={category?.config?.filterType}
              withSelectAll={category?.config?.withSelectAll}
              categoryId={category.id}
            />
          </PaddingWrapper>

          <SubcategoriesSelector
            subcategories={subcategories}
            selected={getSubcategorySelected()}
            handleSelection={setSubcategory}
          />
        </ScrollLockContainer>

        <MainContent>
          <Transition
            timeout={400}
            classNames={"subcategories"}
            transitionKey={getSubcategorySelected().id}
          >
            {() => (
              <WithTransition
                enter={SLIDE_IN_UP}
                exit={SLIDE_OUT_DOWN}
                className={"subcategories"}
                fullScreen={false}
                timeExit="2s"
                position="static"
              >
                {getSubcategorySelected().products.map((product) =>
                (
                  <Product
                    {...product}
                    key={product.id}
                  />
                )
                )}
              </WithTransition>
            )}
          </Transition>
        </MainContent>
      </Container>
      {isSelectorShown && (
        <Selections
          handleClose={setIsSelectorShown}
          selections={utils.getCategoriesWithActiveProducts()}
          handleSelection={(cat) => {
            history.replace(getRouteWithParams("/menu", cat, utils.getSubcategoriesWithActiveProducts(cat.id)[0]));
          }}
          selected={category}
        />
      )}
      <FloatButton headerLabel={getSubcategorySelected().title} />
    </WithTransition>
  );
};

export default ProductsPage;
