import MuiDialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { BLUE } from "resources/Colors";
import useI18n from "state/i18n";
import Inactive86Label from "./Product/components/Inactive86Label";
import AddIcon from "resources/img/Icon-add.svg";

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    overflow: visible;
    max-width: 35vh;
    border-radius: 16px;
  }
`;

const Container = styled.div`
  position: relative;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 27px;
`;

const ProductTitle = styled.span`
  font-size: 16px/19px;
  font-weight: bold;
  @media only screen and (max-width: 440px) {
    font-size: 1.2rem;
  }
`;

const DialogTitle = styled.span`
    font: normal normal normal 16px/19px Montserrat;
    @media only screen and (max-width: 440px) {
        font-size: 1.2rem;
      }
`;

const Price = styled.span`
  vertical-align: middle;
  display: inline-block;
  line-height: 27px;
`;

const Content = styled.div`
  padding: 1rem 1.5rem;
  position: relative;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${BLUE};
  border-radius: 6px;
  height: 100%;
  width: 12rem;
  border: none;
  box-shadow: 0px 8px 24px #0332693D;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogCloseButton = styled.div`
  padding: 5px;
  position: absolute;
  right: 0;
  top: -35px;
`;


const VariantContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  align-items: center;
  gap: 25px; 
  margin-bottom: 50px;
  width: 100%;
`;

const VariantTitle = styled.span`
  font-size: 16px/19px;
  font-weight: bold;
  margin: 0;
  justify-self: start;
`;

const BoldLabel = styled.span`
  font-size: 16px/19px;
  font-weight: bold;
  margin: 0;
  justify-self: end;
`;

const NormalLabel = styled.span`
    font-size: 16px/19px;
    margin: 0;
    justify-self: end;
`;

const DialogTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-top: 32px;
    margin-bottom: 40px;
`;

const Img = styled.img`
  width: 16px;
  height: 16px;
  justify-self: end;
`;

export default function VariantSelectionDialog({ open, handleClose, handleMultipleOrders, product }) {
  const currOrder = useMemo(() => {
    return product.variants?.reduce((acc, curr) => {
      acc[curr.id] = product.amounts?.find(a => +a.id === +curr.id)?.count || 0;
      return acc;
    }, {});
  }, [product]);
  const [order, setOrder] = useState({});
  useEffect(() => currOrder && setOrder(currOrder), [currOrder]);

  const [i18n] = useI18n();
  const is86Inactive = ((!!product && product.status === 'active' && product.stockTotal === 0) ? true : false);

  const handleOrderByVariant = (variantId) => {
    setOrder(order => ({ ...order, [variantId]: (order[variantId] || 0) + 1 }))
  }

  const handleAdd = () => {
    handleMultipleOrders(order);
    handleClose();
  }

  return (
    <Dialog
      key={product?.id}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      onClose={handleClose}
    >
      <Container>
        <DialogCloseButton onClick={handleClose}>
          <CloseIcon style={{ fill: "white" }} />
        </DialogCloseButton>

        <Content>

          <DialogTitleContainer>
            <DialogTitle>{i18n.tag("MAKE_YOUR_SELECTION")}</DialogTitle>
          </DialogTitleContainer>

          <TitleContainer>
            <ProductTitle>{product?.title}</ProductTitle>
            {
              product?.variants?.length < 2 && <Price>{product?.config?.marketPrice ? 'MKT' : `$${product?.variants[0].price}`}</Price>
            }
          </TitleContainer>
          {
            product?.variants?.length > 1 && (
              <VariantContainer>
                {
                  product?.variants.map((variant, index) => (
                    <>
                      <VariantTitle>{variant.title}</VariantTitle>
                      <NormalLabel>{product?.config?.marketPrice ? 'MKT' : `$${variant.price}`}</NormalLabel>
                      <BoldLabel>{order[variant.id]}</BoldLabel>
                      <Img src={AddIcon} onClick={() => handleOrderByVariant(variant.id)} />
                    </>
                  ))
                }
              </VariantContainer>
            )
          }
          {is86Inactive ? (
            <Inactive86Label />
          ) : (
            <ButtonWrapper>
              <Button onClick={handleAdd}>{i18n.tag("ADD_SELECTION")}</Button>
            </ButtonWrapper>
          )}
        </Content>
      </Container>
    </Dialog>
  );
}
