import { all, put } from 'redux-saga/effects';
import ACTIONS from './actions';


function* add(action) {
    yield put({ type: ACTIONS.ADD, payload: action.payload })
}

function* remove(action) {
    yield put({ type: ACTIONS.REMOVE, payload: action.payload })
}

export default function* sagas() {
    yield all([add, remove]);
}