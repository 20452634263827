import React from 'react';
import styled from 'styled-components';
import { LIGHT_GRAY, LIGHT } from 'resources/Colors';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IMAGES_URL } from 'resources/constants';
import useI18n from 'state/i18n';
import useConfig from 'state/config';

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding: 0 1rem;
    margin-bottom: 2rem;
    background-color: white;
`;

const CardContainer = styled.div`
    border-radius: 0 0 8px 8px;
    background-color: ${LIGHT_GRAY};
    display: grid;
    &::before {
        content: '';
        padding-bottom: 110%;
        display: block;
    }

    &::before,
    .space {
        grid-area: 1 / 1 / 2 / 2;
    }
`;

const MessageContainer = styled.a`
    background-color: white;
    box-shadow: 0px 4px 12px #03326914;
    display: grid;
    grid-template-rows: 1fr 1fr;
    text-decoration: none;
    border-radius: 0 0 8px 8px;
`;

const Message = styled.div`
    margin: 0;
    font-size: 0.875rem;
    color: #0f191b;
    font-weight: 400;
    text-align: center;
    align-self: center;
    p {
        margin: 0px;
    }
`;

const MessageImage = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${({ img }) => img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
`;

const CardElementsContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr 2.8rem;
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: white;
`;

const CardImage = styled.div`
    width: 100%;
    height: 100%;
    background-image: url('${IMAGES_URL}/${({ img }) => img ? 'mini.' + img : 'noimage.svg'}');
    background-size: ${({ img }) => (img ? 'cover' : '60%')};
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
`;

const Loading = withStyles(() => ({
    root: {
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)',
        color: LIGHT,
        zIndex: 1,
    },
}))(CircularProgress);

const CardFooter = styled.div`
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    padding: 0 11px;
`;

const Title = styled.span`
    font-weight: bold;
    font-size: 1rem;
    margin-right: 3px;
    @media only screen and (max-width: 440px) {
        font-size: 0.85rem;
    }
`;

const CategoryCard = ({ category, lang }) => {
    const langProp = lang ? 'en' : 'es';
    return (
        <>
            <ImageWrapper>
                <Loading />
                <CardImage img={category.img} />
            </ImageWrapper>
            <CardFooter>
                <Title>
                    {category.config.title
                        ? category.config.title[langProp]
                        : category.title}
                </Title>
            </CardFooter>
        </>
    );
};

const CategoryMessage = ({ payload, lang }) => {
    const linkDestination = {
        url: !!payload.linkDestination && !!payload.linkDestination.url ? payload.linkDestination.url : payload.url,
        target: !!payload.linkDestination ? !!payload.linkDestination.isSectionOnMenu ? '_self' : '_blank' : '_self'
    }
    return (!!payload.active ? (
        <CardContainer>
            <MessageContainer
                href={linkDestination.url}
                className="space"
                target={linkDestination.target}
            >
                <ImageWrapper>
                    <MessageImage img={`${IMAGES_URL}/${payload.img}`} />
                </ImageWrapper>
                <Message
                    dangerouslySetInnerHTML={{
                        __html: lang ? payload.message.en : payload.message.es,
                    }}
                />
            </MessageContainer>
        </CardContainer>) : null
    );
};

const CategoriesLayout = ({ tree, handleCategory }) => {
    const [, , lang] = useI18n();
    const [config] = useConfig();
    return (
        <GridWrapper>
            {tree.map((category) => (
                <CardContainer
                    onClick={() => handleCategory(category)}
                    key={category.id}
                >
                    <CardElementsContainer className="space">
                        <CategoryCard category={category} lang={lang} />
                    </CardElementsContainer>
                </CardContainer>
            ))}
            {config && config['menu-category-card'] && (
                <CategoryMessage payload={config['menu-category-card'].payload} lang={lang} />
            )}
        </GridWrapper>
    );
};

export default CategoriesLayout;
