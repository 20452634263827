import MuiDialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { BLUE } from "resources/Colors";
import { IMAGES_URL } from "resources/constants";
import useI18n from "state/i18n";
import Figure from "./Product/components/Figure";
import Inactive86Label from "./Product/components/Inactive86Label";
import AddIcon from "resources/img/Icon-add.svg";

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    overflow: visible;
    max-width: 35vh;
  }
`;

const Container = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 35vh;
  object-fit: cover;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;
`;

const ProductTitle = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  @media only screen and (max-width: 440px) {
    font-size: 1.2rem;
  }
`;

const Price = styled.span`
  vertical-align: middle;
  display: inline-block;
  line-height: 27px;
`;

const Content = styled.div`
  padding: 1rem 1.5rem;
  position: relative;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${BLUE};
  border-radius: 6px;
  height: 100%;
  width: 12rem;
  border: none;
  box-shadow: 0px 8px 24px #0332693D;
`;

const Tag = styled.p`
  font-size: 0.8rem;
  opacity: 0.8;
`;

const Description = styled.div`
  font-size: 0.875rem !important;
  p {
    margin: 5px 0;
  }
`;

const BoldLabel = styled.span`
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0;
  justify-self: end;
`;

const Img = styled.img`
  width: 16px;
  height: 16px;
  justify-self: end;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogCloseButton = styled.div`
  padding: 5px;
  position: absolute;
  right: 0;
  top: -35px;
`;


const VariantContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  align-items: center;
  gap: 10px; 
  margin-bottom: 5px;
  width: 100%;
`;

const VariantName = styled.span`
  font-size: 0.8rem;
  margin: 0;
`;

const VariantPrice = styled.span`
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0;
`;

export default function ProductDialog({ open, handleClose, handleMultipleOrders, product }) {
  const currOrder = useMemo(() => {
    return product.variants?.reduce((acc, curr) => {
      acc[curr.id] = product.amounts?.find(a => +a.id === +curr.id)?.count || 0;
      return acc;
    }, {});
  }, [product]);

  const hasMultipleVariants = useMemo(() => product?.variants?.length > 1, [product]);

  const [order, setOrder] = useState({});
  useEffect(() => currOrder && setOrder(currOrder), [currOrder]);

  const [i18n] = useI18n();
  const is86Inactive = ((!!product && product.status === 'active' && product.stockTotal === 0) ? true : false);

  const handleOrderByVariant = (variantId) => {
    setOrder(order => ({ ...order, [variantId]: (order[variantId] || 0) + 1 }))
  }

  const handleAdd = () => {
    hasMultipleVariants ? handleMultipleOrders(order) : handleMultipleOrders({ [product.variants[0].id]: 1 });
    handleClose();
  }

  return (
    <Dialog
      key={product?.id}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      onClose={handleClose}
      style={{ borderRadius: "20px" }}
    >
      <Container>
        <DialogCloseButton onClick={handleClose}>
          <CloseIcon style={{ fill: "white" }} />
        </DialogCloseButton>
        <Figure is86Inactive={is86Inactive}>
          <Image
            src={`${IMAGES_URL}/${product?.img ? product?.img : "noimage.svg"}`}
          />
        </Figure>

        <Content>
          <TitleContainer>
            <ProductTitle>{product?.title}</ProductTitle>
            {
              !hasMultipleVariants && <Price>{product?.config?.marketPrice ? 'MKT' : `$${product?.variants?.[0]?.price}`}</Price>
            }
          </TitleContainer>
          {
            hasMultipleVariants && (
              <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                {
                  product?.variants.map((variant, index) => (
                    <VariantContainer key={index}>
                      <VariantName>{variant.title}</VariantName>
                      <VariantPrice>{product?.config?.marketPrice ? 'MKT' : `$${variant.price}`}</VariantPrice>
                      <BoldLabel>{order[variant.id]}</BoldLabel>
                      <Img src={AddIcon} onClick={() => handleOrderByVariant(variant.id)} />
                    </VariantContainer>
                  ))
                }
              </div>
            )
          }
          <Description style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: product?.description }} />
          {product?.tags?.map((tag) => (
            <Tag key={tag.id}>{`▶ ${tag.title}`}</Tag>
          ))}
          {is86Inactive ? (
            <Inactive86Label />
          ) : (
            <ButtonWrapper>
              <Button onClick={handleAdd}>{i18n.tag("ADD_SELECTION")}</Button>
            </ButtonWrapper>
          )}
        </Content>
      </Container>
    </Dialog>
  );
}
