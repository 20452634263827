const translate = (raw, lang) =>
  raw.map((category) => ({
    id: category.id,
    title: translateField(category.translations, lang, "title"),
    message: category.config?.message
      ? {
        ...category.config.message,
        title: category.config.message.translations
          ? translateField(
            category.config.message.translations,
            lang,
            "title"
          )
          : "",
      }
      : null,
    config: category.config,
    img: category.img,
    subcategories: category.subcategories.map((subcategory) => ({
      id: subcategory.id,
      products: translateProducts(subcategory.products, lang),
      title: translateField(subcategory.translations, lang, "title"),
      img: subcategory.img,
    })),
    products: translateProducts(category.products, lang),
  }));

export const getTranslatedProductsMap = (products, lang) => {
  const productsMap = {};
  products
    .map((p) => traslanteProduct(p, lang))
    .forEach((p) => {
      productsMap[p.id.toString()] = p;
    });
  return productsMap;
};

export const translateProducts = (products, lang) =>
  products.map((p) => traslanteProduct(p, lang));

const traslanteProduct = (p, lang) => ({
  id: p.id,
  title: translateField(p.translations, lang, "title"),
  description: translateField(p.translations, lang, "description"),
  price: p.price,
  tags: translateAttributes(p.tags, lang),
  ingredients: translateAttributes(p.ingredients, lang),
  img: p.img,
  status: p.status,
  stockTotal: p.stockTotal,
  config: p.config,
  variants: p.variants?.map(v => ({
    ...v,
    title: v.translations !== null ?
      translateField(v.translations, lang, null) :
      null
  }))
});

export const translateField = (translation, lang, field) =>
  field !== null ?
    +lang === 0 ? translation.es[field] : translation.en[field] :
    +lang === 0 ? translation.es : translation.en;

const translateAttributes = (attributes, lang) =>
  Array.isArray(attributes)
    ? attributes.map((attribute) => translateAttribute(attribute, lang))
    : [];

export const translateAttribute = (attribute, lang) => ({
  id: attribute.id,
  config: attribute.config,
  title: translateField(attribute.translations, lang, "title"),
});

export default translate;
