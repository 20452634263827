import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled, { keyframes } from "styled-components";

export const SLIDE_IN_UP = "slideInUp";
export const SLIDE_OUT_DOWN = "slideOutDown";
export const SLIDE_IN_LEFT = "slideInLeft";
export const SLIDE_OUT_LEFT = "slideOutLeft";
export const SLIDE_IN_RIGHT = "slideInRight";
export const SLIDE_OUT_RIGHT = "slideOutRight";

const slideInUp = keyframes`
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const slideOutDown = keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
`;

const slideInLeft = keyframes`
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const slideOutLeft = keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
`;

const slideOutRight = keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);ƒ
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

`;

const slideInRight = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const transitions = {
  slideInUp,
  slideOutDown,
  slideInRight,
  slideOutRight,
  slideInLeft,
  slideOutLeft,
};

const defaultTime = "0.4s";

const getTransitionFactory = (transitionKey) => transitions[transitionKey];

const ComponentContainer = styled.div`
  background-color: white;
  position: ${({ position }) => (position ? position : "absolute")};
  width: 100%;
  &.${({ className }) => className}-enter {
    animation: ${({ enter }) => enter}
      ${({ timeEnter }) => (!!timeEnter ? timeEnter : defaultTime)} forwards;
    position: ${({ position }) => (position ? position : "absolute")};
  }
  &.${({ className }) => className}-exit {
    animation: ${({ exit }) => exit}
      ${({ timeExit }) => (!!timeExit ? timeExit : defaultTime)} forwards;
    position: ${({ position }) => (position ? position : "absolute")};
  }
`;

const PageContainer = styled(ComponentContainer)`
  min-height: 100vh;
  min-width: 100%;
`;

export const WithTransition = ({
  enter,
  exit,
  fullScreen = true,
  className = "page",
  children,
  ...props
}) => {
  const enterTransition = getTransitionFactory(enter);
  const exitTransition = getTransitionFactory(exit);

  const transitionProps = {
    enter: enterTransition,
    exit: exitTransition,
    className,
    ...props,
  };

  return (
    <React.Fragment>
      {fullScreen ? (
        <PageContainer {...transitionProps}>{children}</PageContainer>
      ) : (
        <ComponentContainer {...transitionProps}>{children}</ComponentContainer>
      )}
    </React.Fragment>
  );
};

export const Transition = ({
  timeout,
  classNames,
  transitionKey,
  children,
}) => (
  <TransitionGroup component={null}>
    <CSSTransition
      timeout={timeout}
      classNames={classNames}
      key={transitionKey}
    >
      {children}
    </CSSTransition>
  </TransitionGroup>
);
