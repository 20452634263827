import Axios from "axios";

export const API = process.env.REACT_APP_API;

const apiRequest = (resource, method) => async (params) => {
  const url = `${API}${resource}`;
  try {
    const config = {
      method,
      url,
    };
    if (!!params) {
      if (method === "get") config["params"] = params;
      else config["data"] = params;
    }
    const response = await Axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getProducts = apiRequest("/menu/tree", "get");
export const getConfig = apiRequest("/menu/config/public", "get");
export const register = apiRequest("/menu/service/register", "post");
export const sync = apiRequest("/menu/service/sync", "post");
export const makeOrder = apiRequest("/menu/service/order", "post");
export const callService = apiRequest("/menu/service/callservice", "post");
export const webPush = apiRequest("/menu/service/notifyme", "post");
