import styled from "styled-components";
import React from "react";
import useI18n from "../../../../../state/i18n";
import { BLUE } from "../../../../../resources/Colors";

const CategoriesWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0;
`;

const SeeAllButton = styled.span`
  color: ${BLUE};
  font-weight: 600;
  font-size: 0.875rem;
`;

const CategoryTitle = styled.h1`
  font-size: 1.6rem;
  margin-top: 1.4rem;
  margin-bottom: 10px;
  font-weight: normal;

`;

const Categories = ({ handler, title }) => {
  const [i18n] = useI18n();
  return (
    <CategoriesWrapper>
      <CategoryTitle>{title}</CategoryTitle>
      <SeeAllButton onClick={handler}>{i18n.tag("SEE_ALL")}</SeeAllButton>
    </CategoriesWrapper>
  );
};

export default Categories;
