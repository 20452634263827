import React, { createContext } from "react";
import { useState } from "react";

export const FiltersContext = createContext();

export const FiltersContextProvider = ({ children }) => {
  const [tagsSelected, setTagsMap] = useState(new Map());

  const includesTag = (id, tag) =>
    tagsSelected.get(id)?.some((tagSelected) => tagSelected.id === tag.id);

  const setTag = (id, filterType, tag) => {
      if (filterType === 'unique') {
          includesTag(id, tag)
              ? setTagsMap(new Map(tagsSelected.set(id, [])))
              : setTagsMap(new Map(tagsSelected.set(id, [tag])));
      } else {
          includesTag(id, tag)
              ? setTagsMap(
                  new Map(tagsSelected.set(
                      id,
                      tagsSelected.get(id).filter(
                        (tagSelected) => tagSelected.id !== tag.id
                      )
                  ))
                )
              : setTagsMap(new Map(tagsSelected.set(id, [...tagsSelected.get(id), tag])));
      }
  };

  const setTags = (id, tags) => {
    setTagsMap(new Map(tagsSelected.set(id, tags)));
  };

  const resetTags = (id) => {
    setTagsMap(new Map(tagsSelected.set(id, [])));
  };

  return (
    <FiltersContext.Provider value={{tagsSelected, setTag, resetTags, setTags, includesTag, includesTag}}>
      {children}
    </FiltersContext.Provider>
  );
};
