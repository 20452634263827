import ACTIONS from './actions';
import APPACTIONS from '../app.actions';

class Dispatcher {

    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    translate = (lang) => {
        this.dispatch({ type: ACTIONS.TRANSLATE, payload: lang });
        this.dispatch({ type: APPACTIONS.TRANSLATE });
    };

}


export default Dispatcher;
