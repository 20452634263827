import styled from "styled-components";
import { BORDER_BLUE } from "resources/Colors";

const Container = styled.div`
  display: flex;
  ${({ column }) => !!column && `flex-direction: column`};
  justify-content: space-between;
  position: relative;
  padding-left: 0;
  @media (min-width: 400px) { 
    padding-left: 6px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  background-color: white;
  justify-content: space-between;

  position: relative;
  width: 100%;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
`;

const Top = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  flex: 1;
  overflow: scroll;
  margin-top: 10px;
  font-size: 0.8rem !important;
  p {
    margin: 5px 0;
  }

  @media (min-width: 400px) { 
    margin-top: 5px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  width: 15%;
  margin-left: 1rem;
`;

const Title = styled.span`
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  max-width: 75%;
`;

const Tags = styled.span`
  font-size: 12.5px;
  opacity: 0.8;
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;
`;

const Price = styled.p`
  margin: 0;
  font-size: 0.8rem;
  @media only screen and (max-width: 440px) {
    font-size: 0.7rem;
  }
`;

const Button = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

const TextButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  padding: 0.5rem;
  background-color: #4990e2;
  color: white;
`;

const Description = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px;

  @media only screen and (min-width: 400px) {
    margin-top: 10px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline;
`;

const RedSpan = styled.span`
  font-size: small;
  color: red;
`;

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;
`;

const ProductCounter = styled.p`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  margin-right: 5px;
`;

const Img = styled.img`
  width: 24px;
  height: 24px;
`;

const TitleContainer = styled.div`
  width: 60%;
  display: flex;
  padding-left: 24px;
`;

const SimpleImgContainer = styled.div`
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SimpleCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  border-radius: 0 8px 8px 0;
  border: ${({ isSelected }) => isSelected ? `2px solid ${BORDER_BLUE}` : `2px solid white`};
  height: 4.5rem;
  box-shadow: 1px 2px 6px #ccc;
  border-radius: 8px;
  cursor: default;
  margin-bottom: 15px;
  position: relative;
  box-sizing: border-box;
`;

const VariantContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  width: 100%;
`;

const Variant = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const VariantName = styled.span`
  font-size: 0.8rem;
  margin: 0;
`;

const VariantPrice = styled.span`
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0;
`;

export {
  Container,
  Left,
  Right,
  Top,
  Bottom,
  Title,
  Tags,
  Price,
  Button,
  TextButton,
  ColumnContainer,
  Description,
  TextContainer,
  RedSpan,
  ProductCounter,
  ImgContainer,
  Img,
  TitleContainer,
  SimpleImgContainer,
  SimpleCard,
  Footer,
  VariantContainer,
  Variant,
  VariantName,
  VariantPrice
};
