import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import BackHeader from "components/BackHeader";
import Product from "components/Product";
import useI18n from "state/i18n";
import useOrder from "state/order";
import useProducts from "state/products";
import {
  WithTransition,
  SLIDE_IN_UP,
  SLIDE_OUT_DOWN,
} from "styles/transitions/transitions";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
`;

const Title = styled.h1`
  font-weight: normal;
`;

const Order = () => {
  const [order] = useOrder();
  const [, , utils] = useProducts();
  const [i18n] = useI18n();
  const products = utils.find(order);
  const history = useHistory();
  const { headerLabel = i18n.tag("FULL_MENU") } = useLocation();
  const { redirectTo = "/menu" } = useLocation();

  return (
    <WithTransition enter={SLIDE_IN_UP} exit={SLIDE_OUT_DOWN}>
      <Container>
        <BackHeader
          label={headerLabel}
          handler={() => history.push(redirectTo)}
        />
        <Title>{i18n.tag("ORDER_DETAILS")}</Title>
        {products.map((product) => {
          if (product.status !== "active") return
          const inOrder = order.filter(o => product.variants.some(v => +v.id === +o.id && v.active));
          return (
            <>
              {
                product.variants.
                  filter(({ id }) => inOrder.find(order => +order.id === +id)).
                  map(variant => (
                    <Product
                      {...product}
                      key={product.id}
                      remove
                      perVariant
                      variantId={variant.id}
                    />
                  ))
              }
            </>
          )
        })}
      </Container>
    </WithTransition>
  );
};

export default Order;
