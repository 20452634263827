import React, { useEffect } from "react";
import openSocket from "socket.io-client";
import { API } from "./resources/Api";
import useProducts from './state/products';

const socket = openSocket(`${API}`);

const Socket = () => {
  const [, productsDispatcher] = useProducts()

  useEffect(() => {
    socket.off('menu').on('menu', () => productsDispatcher.fetchStart());
  }, []);

  return (<></>);
};

export default Socket;