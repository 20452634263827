import React, { createContext } from "react";
import { useState } from "react";

export const ExpandedContext = createContext();

export const ExpandedContextProvider = ({ children }) => {
    const [opened, setOpened] = useState([]);
    const [allOpened, setAllOpened] = useState(false);

    const includesSubcategory = (id) => {
        return opened.find((sc) => sc === id) !== undefined;
    };

    const toggleSubcategory = (id) => {
        const toOpen = [...opened];
        if(includesSubcategory(id)){
            const idPosition = toOpen.indexOf(id);
            toOpen.splice(idPosition, 1)
        } else {
            toOpen.push(id);
        }
        setOpened(toOpen);
        if(toOpen.length===0){
            setAllOpened(false);
        }
    }

    const openAll = (scs) => {
        setAllOpened(true);
        setOpened(scs);
    };

    const collapseAll = () => {
        setAllOpened(false);
        setOpened([]);
    }

    return (
        <ExpandedContext.Provider value={[allOpened, toggleSubcategory, includesSubcategory, openAll, collapseAll]}>
            {children}
        </ExpandedContext.Provider>
    );
};
