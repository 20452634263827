import React, { useState, useContext } from "react";
import styled from "styled-components";
import FloatButton from "components/SelectionsFloatButton";
import useProducts from "state/products";
import {
  SLIDE_IN_UP,
  SLIDE_OUT_DOWN,
  Transition,
  WithTransition
} from "styles/transitions/transitions";
import Filters from "../../containers/Filters";
import Topbar from "containers/Topbar";
import { Categories } from "./Categories";
import Message from "./Message";
import Subcategories from "./Subcategories";
import { BLUE } from "resources/Colors";
import GalleryIcon from "resources/img/gallery-icon.svg";
import ListViewIcon from "resources/img/list-view-icon.svg";
import { ExpandedContext } from "pages/Menu/ExpandedProvider";
import CollapseIcon from "resources/img/collapse-icon.svg";
import ExpandIcon from "resources/img/expand-icon.svg";
import useI18n from "state/i18n";
import { ViewContext } from "ViewProvider";
import Product from "components/Product";

const Top = styled.header`
  width: 100%;
`;

const FiltersWrapper = styled.div`
  padding: 15px 1rem 10px 1rem;
`;

const ViewToolsWrapped = styled.div`
  display: flex;
  margin: 1rem;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
`;

const ToolWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const ToolLabel = styled.p`
  font-weight: 600;
  font-size: 0.875rem;
  color: ${BLUE};
  margin: 0;
  margin-right: 10px;
`;

const ToolIcon = styled.img`
  height: 20px;
  width: auto;
`;

const ScrollLockContainer = styled.div`
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 5;
`;

const ListWrapper = styled.div`
  display: block;
  padding: 0 1rem;
`;

const Container = styled.main`
  margin-top: 10px;
  width: 100%;
  min-height: 21rem;
  overflow-y: scroll;
  max-height: 100%;
  padding-bottom: 5.5rem;
  background-color: white;
  ::-webkit-scrollbar {
    display: none;
  }
`;


const HomeComponent = ({
  tree,
  handleCategory,
  handleSubcategory,
  subcategories,
  category,
  rootProducts,
}) => {
  const [, , utils] = useProducts();
  const [showMessage, setShowMessage] = useState(false);
  const togleMessage = () => setShowMessage(!showMessage);
  const className = "categories";
  const { getView, toggleView } = useContext(ViewContext);
  const [allOpened, , , openAll, collapseAll] = useContext(ExpandedContext);
  const [i18n] = useI18n();

  return (
    <>
      <Top>
        <Topbar toggler={togleMessage} />
      </Top>
      {showMessage && <Message message={tree.find(cat => cat.id === category?.id)?.message} />}

      <ScrollLockContainer>

        <Categories
          categories={tree}
          handler={handleCategory}
          idSelected={category.id}
        />

        <FiltersWrapper>
          <Filters
            tags={utils.getTagsInCategory(category.id)}
            type={category?.config?.filterType}
            withSelectAll={category?.config?.withSelectAll}
            categoryId={category.id}
          />
        </FiltersWrapper>

      </ScrollLockContainer>

      <ViewToolsWrapped>
        <ToolWrapper onClick={() => toggleView(category.id)}>
          <ToolLabel>{i18n.tag("CHANGE_VIEW")}</ToolLabel>
          <ToolIcon src={getView(category.id) ? GalleryIcon : ListViewIcon} alt='change-view-icon' />
        </ToolWrapper>
        {getView(category.id) && <ToolWrapper onClick={() => {
          if (allOpened) {
            collapseAll();
          } else {
            openAll(utils.getSubcategoriesWithActiveProducts(category.id).map(sc => sc.id));
          }
        }}>
          <ToolLabel>{allOpened ? i18n.tag("COLLAPSE_ALL") : i18n.tag("EXPAND_ALL")}</ToolLabel>
          <ToolIcon src={allOpened ? CollapseIcon : ExpandIcon} alt='expand-icon' />
        </ToolWrapper>}
      </ViewToolsWrapped>

      <Transition
        timeout={400}
        classNames={className}
        transitionKey={category.id}
      >
        <WithTransition
          enter={SLIDE_IN_UP}
          exit={SLIDE_OUT_DOWN}
          className={className}
          fullScreen={false}
          timeExit="2s"
          position="relative"
        >
          <Container>
            <ListWrapper>
              {rootProducts.map((product) =>
              (
                <Product
                  {...product}
                  key={product.id}
                />
              )
              )}
            </ListWrapper>
            <Subcategories
              subcategories={subcategories}
              handleSubcategory={handleSubcategory}
              viewAsList={getView(category.id)}
            />
          </Container>
        </WithTransition>
      </Transition>
      <FloatButton headerLabel={i18n.tag("FULL_MENU")} redirectTo={`/menu`} />
    </>
  );
};
export default HomeComponent;
