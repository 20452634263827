import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Dispatcher from './dispatcher';
import selector from './selector';

const useOrder = () => {
    const dispatcher = new Dispatcher(useDispatch());
    const state = useSelector(selector, shallowEqual);
    const utils = makeUtils(state);

    return [state, dispatcher, utils]
}

// PENDIENTE UTILIZAR LOS PRODUCTOS YA EXISTENTES Y RETORNAR 
// EL LISTADO DE FAVORITOS TRADUCIDO Y COMPLETO

function makeUtils(state) {
    return {
        isFavorite: (id) => {
            const index = state.findIndex((e) =>  e.id === id );

            return index !== -1;
        }
    }
}




export default useOrder;
