import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseIcon from "resources/img/close-Icon.svg";
import { BLUE } from "resources/Colors";
import { ICONS_URL } from "resources/constants";
import useI18n from "state/i18n";

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 200;
  transition-duration: 0.5s;
  &.transition {
    background-color: #00000099;
  }
`;

const SelectionsLayer = styled.div`
  position: fixed;
  bottom: -100vh;
  width: 100vw;
  background-color: ${BLUE};
  z-index: 200;
  border-radius: 16px 16px 0 0;
  transition-duration: 0.5s;
  &.transition {
    bottom: 0vh;
  }
`;

const Container = styled.div`
  margin: 1.5rem;
`;


const Close = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: white;
`;

const ItemsAlignmentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
`;

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-column-gap: 16px;
  align-items: center;
  width: 100%;
`;

const Item = styled.p`
  color: white;
  font-weight: ${({ selected }) => (!!selected ? "700" : "500")};
  font-size: ${({ selected }) => (!!selected ? "1.2rem" : "1rem")};
  margin: ${({ selected }) => (!!selected ? "0.7em" : "1em")};
`;

const ItemIcon = styled.img`
  width: 32px;
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(184deg) brightness(103%) contrast(101%);
`;

const SelectedBar = styled.div`
  width: 32px;
  height: 4px;
  border-radius: 4px;
  background-color: white;
  margin-left: 1em;
`;

export const Selections = ({
  selections,
  selected,
  handleSelection,
  handleClose,
}) => {

  const [i18n] = useI18n();

  const selectAndClose = (newSelected) => {
    handleSelection(newSelected);
    onClose();
  };

  const onClose = () => {
    setTransition('');
    setTimeout(()=>{ handleClose(false);}, 500);
  }

  const [transition, setTransition] = useState('');

  useEffect(()=>{setTransition('transition')},[]);

  useEffect(() => {
    window.onscroll = () => {
      onClose();
    }
  }, []);

  return (
    <Background onClick={() => onClose()} className={transition}>
    <SelectionsLayer className={transition}>
      <Container>
        <Close onClick={() => onClose()}>
          <img src={CloseIcon} alt="" />
        </Close>
        <Title>{i18n.tag("SELECT_A_CATEGORY")}</Title>
        <ItemsAlignmentWrapper>
          <ItemsWrapper>
            {selections.map((selection) => (
              <ItemContainer key={selection.id} onClick={() => selectAndClose(selection)}>
                <ItemIcon src={`${ICONS_URL}/${selection?.config?.icon}`} alt='category-icon' />
                <div>
                  <Item selected={selected.id === selection.id}>
                    {`${selection.title}`}
                  </Item>
                  {selected.id === selection.id && <SelectedBar />}
                </div>
              </ItemContainer>
            ))}
          </ItemsWrapper>
        </ItemsAlignmentWrapper>
      </Container>
    </SelectionsLayer>
    </Background>    
  );
};
