import styled, { keyframes } from 'styled-components';
import { BLUE } from "resources/Colors";

export const ButtonLabel = styled.span`
    font-weight: bold;
    font-size: 1rem;

    p {
      margin: 0;
      padding: 0;
    }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
`;

export const Button = styled.button`
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${BLUE};
  border-radius: 8px;
  border: none;
  width: 100%;
  box-shadow : 0px 8px 24px #0332693D;
  padding : 15px 0;
`;

const ButtonAnimation = keyframes`
    to {
      background-size: 100% 100%;
    }
`;

export const AnimatedButton = styled(Button)`
  background-image: linear-gradient(#ffffff, #ffffff);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  color: ${BLUE};
  animation: 0.5s ${ButtonAnimation} forwards;
  position: absolute;
  border: solid 2px ${BLUE};
`;

export const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    &:first-child {
        margin-top: 10px;
    }
`;

export const ItemLabel = styled.span`
    font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
    font-size: 0.875rem;
`;

export const LottieWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 4rem;
    margin-right: 1rem;
    z-index: 10;
    @media only screen and (max-width: 440px) {
        width: 2.5rem;
        margin-right: 1rem;
    }
`;

const TriggerTest = keyframes`
  to {
    background-size: 100% 100%;
    color: #fff;
  }
`;

export const Background = styled.div`
    background-image: linear-gradient(
        ${({ color }) => color},
        ${({ color }) => color}
    );
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    height: 100%;
    border-radius: 10px;
    position: absolute;
    animation: 0.5s ${TriggerTest} forwards;
    z-index: 1;
    left: 4.5rem;
    width: calc(100% - 4.5rem);
    @media only screen and (max-width: 440px) {
        left: 3rem;
        width: calc(100% - 3rem);
    }
`;

export const LabelsWrapper = styled.div`
    z-index: 10;
`;

export const Title = styled.h1`
    font-size: 1.2rem;
    padding: 0 10px;
    margin: 0;
    text-align: center;
    flex: 1;
    margin-bottom: 10px;
`;