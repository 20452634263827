import { fade, InputBase, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../components/Loader";
import { formatString, pipe } from "../../resources/helpers";
import useI18n from "../../state/i18n";
import useProducts from "../../state/products";
import {
  SLIDE_IN_UP,
  SLIDE_OUT_DOWN, WithTransition
} from "../../styles/transitions/transitions";
import { ProductsByCategories } from "./containers/ProductsByCategories";

const Wrapper = styled.div`
  padding-top: 2.6rem;
  background-color: white;
`;

const useStyles = makeStyles((theme) => ({
  search: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: "#fff",
    zIndex: 300,
    maxWidth: '414px',
  },
  searchIcon: {
    flex: 1,
    marginLeft: theme.spacing(1),
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    flex: 6,
    color: "inherit",
  },
  inputInput: {
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  closeIcon: {
    flex: 1,
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function getProductsWithCategories(data) {
  return data.map((category) => ({
    ...category,
    products: [
      ...category.products.map((product) => ({
        ...product,
        translations: product.translations,
      })),
      ...category.subcategories
        .reduce((acc, curr) => acc.concat([...curr.products]), [])
        .map((product) => ({
          ...product,
          translations: product.translations,
        })),
    ],
  }));
}

const Search = () => {
  const [search, setSearch] = useState("");
  const [productsFiltered, setProductsFiltered] = useState([]);
  const [products] = useProducts();
  const [i18n] = useI18n();

  const [data] = useState(pipe(getProductsWithCategories)(products.tree));

  const history = useHistory();
  const classes = useStyles();

  const { redirectTo = "/menu" } = useLocation();

  const handleClose = () => history.push(redirectTo);

  const handleSearch = (e) => setSearch(e.target.value);

  useEffect(() => {
    const query = search.toLowerCase().trim();

    const productsFiltered = data.map((category) => ({
        ...category,
        products: [
            ...category.products.filter(
                (product) =>
                    formatString(product.title).includes(query) ||
                    product.tags.some((tag) =>
                        formatString(tag.title).includes(query)
                    ) ||
                    product.ingredients.some((ing) =>
                        formatString(ing.title).includes(query)
                    )
            ),
            ...category.subcategories.filter(
              (sc) =>
                formatString(sc.title).includes(query)
            ).reduce((acc, curr)=>[...acc, ...curr.products], []),
            ...(formatString(category.title).includes(query) ? category.products : []),
        ],
    }));

    const removeDuplicates = productsFiltered.map((category) => {
      const products = [
        ...new Map(
          category.products.map((product) => [product.id, product])
        ).values(),
      ];

      return {
        ...category,
        products,
      };
    });

    setProductsFiltered(removeDuplicates);
  }, [search, data]);

  return (
    <WithTransition enter={SLIDE_IN_UP} exit={SLIDE_OUT_DOWN}>
      <Loader isLoading={data.length <= 0}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={i18n.tag("SEARCH_PLACEHOLDER")}
            autoFocus
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearch}
          />
          <div className={classes.closeIcon}>
            <CloseIcon onClick={handleClose} />
          </div>
        </div>
        <Wrapper>
          <ProductsByCategories categories={productsFiltered} />
        </Wrapper>
      </Loader>
    </WithTransition>
  );
};

export default Search;
