import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import styled from "styled-components";
import { LIGHT, BORDER_BLUE } from "../resources/Colors";
import { IMAGES_URL } from "../resources/constants";
import Figure from "./Product/components/Figure";

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 1fr;
  height: 9.5rem;
  box-shadow: 1px 2px 6px #ccc;
  border-radius: 0 8px 8px 0;
  text-align: left;
  cursor: default;
  margin-bottom: 15px;
  position: relative;

  @media (min-width: 400px) { 
    grid-template-columns: 9.5rem 1fr;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 9.5rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 8px;
  border-radius: 0 8px 8px 0;
  border: ${({ isSelected }) => isSelected ? `2px solid ${BORDER_BLUE}` : `2px solid white`};
  border-left: none;
  position: relative;
  overflow: hidden;
`;

const Image = styled.img`
  width:  100%;
  height: 100%;
  object-fit: cover;
`;

const Loading = withStyles(() => ({
  root: {
    position: "absolute",
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)",
    color: LIGHT,
  },
}))(CircularProgress);

const ImageContainer = styled(Figure)`
  min-width: 5rem;
  background-position: center;
  z-index: 3;
  width: 100%;
  position: relative;
`;

const Card = ({ img, is86Inactive, children, handler, isSelected }) => {
  return (
    <Container>
      <ImageWrapper onClick={handler}>
        <Loading />
        <ImageContainer is86Inactive={is86Inactive}>
          <Image src={`${IMAGES_URL}/${img ? "mini." + img : "noimage.svg"}`} />
        </ImageContainer>
      </ImageWrapper>
      <Content isSelected={isSelected}>{children}</Content>
    </Container>
  );
};

export default Card;











