import ACTIONS from './actions';


export default class TreeDispatcher {

    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchStart = () => this.dispatch({ type: ACTIONS.FETCH_START });

    fetchSuccess = (payload) => this.dispatch({ type: ACTIONS.FETCH_SUCCESS, payload });

    fetchError = () => this.dispatch({ type: ACTIONS.FETCH_ERROR });

    translate = (payload) => this.dispatch({ type: ACTIONS.TRANSLATE, payload })

}