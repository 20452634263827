import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getRouteWithParams } from "../../../../resources/helpers";
import useProducts from "../../../../state/products";
import {
  SLIDE_IN_LEFT,
  SLIDE_OUT_LEFT,
  WithTransition,
} from "../../../../styles/transitions/transitions";
import CategoriesLayout from "./containers/CategoriesLayout";
import Topbar from "containers/Topbar";
import styled from "styled-components";
import useI18n from "state/i18n";
import useConfig from "state/config";

const Top = styled.header`
  width: 100%;
`;

const Title = styled.h1`
    margin: 1rem;
    font-size: 1rem;
    color: #0F191B;
    font-weight: 400;
`;

const SelectCategory = () => {
    const [i18n] = useI18n();
    const [products, ,] = useProducts();
    const [, configDispatcher] = useConfig();
    const history = useHistory();

    useEffect(()=>{
        configDispatcher.fetchStart();
    },[]);

    const handleCategory = (newCategory) => {
        history.push(
        getRouteWithParams("/menu", newCategory)
        );
    };

    return (
        <WithTransition enter={SLIDE_IN_LEFT} exit={SLIDE_OUT_LEFT}>
            <Top>
                <Topbar />
            </Top>
            <Title>{i18n.tag("CATEGORY_SELECT_TITLE")}</Title>
            <CategoriesLayout
                tree={products.tree}
                handleCategory={handleCategory}
            />
        </WithTransition>
    );
};

export default SelectCategory;