import ACTIONS from "./actions";
import translate, { getTranslatedProductsMap } from "./translate";

const INITIAL_STATE = {
  raw: [],
  tree: [],
  flat: [],
  products: {},
  variantRelations: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_SUCCESS: {
      const raw = action.payload;
      const flat = getAllProducts(raw);

      return { ...state, raw, flat };
    }
    case ACTIONS.TRANSLATE: {
      const tree = translate(state.raw, action.payload);
      const products = getTranslatedProductsMap(state.flat, action.payload);
      const variantRelations = {};
      for (const [productId, product] of Object.entries(products)) {
        if (Array.isArray(product.variants)) {
          product.variants.forEach((variant) => {
            variantRelations[variant.id] = productId;
          });
        }
      }
      return { ...state, tree, products, variantRelations };
    }

    default:
      return state;
  }
};

function getAllProducts(raw) {
  const list = raw.map((element) => {
    if (element.subcategories) {
      return [...getAllProducts(element.subcategories), ...element.products];
    } else {
      return [...element.products];
    }
  });

  return list.flat(2);
}