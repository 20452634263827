import React from "react";
import styled from "styled-components";
import { IMAGES_URL } from "resources/constants";
import useI18n from "state/i18n";
import { Button, ButtonLabel } from "./tutorial-styles";

const Container = styled.div`
  padding: 0;
  position: relative;
  background-color: ${({promotionBackground}) => !!promotionBackground ? promotionBackground : 'transparent'};
  .promo {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .close {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

const getImage = (payload, lang) => {
  if(!!payload.img) return lang === 1 ? `${IMAGES_URL}/en-${payload.img}` : `${IMAGES_URL}/es-${payload.img}`;

  return payload.imageURL ?? '';
}

const Promotion = ({handleClose, data}) => {
  const [,,lang] = useI18n();
  const {linkURL} = data;

  const handleImageClick = () => {
    if(!!linkURL) {
      window.open(linkURL, '_blank');
    }
    handleClose();
  }

    return (
      <Container onClick={handleImageClick} promotionBackground={data.backgroundColor ?? ''} >
        {(!!data.img || !!data.imageURL) && <img className="promo" src={getImage(data, lang)} alt={"Promotional Image"} />}
      </Container>
    );
}

export default Promotion;