import { all, call } from 'redux-saga/effects';
import i18n from './i18n/sagas';
import order from './order/sagas';
import products from './products/sagas';
import config from './config/sagas';

export default function* rootSaga() {
    yield all([
        call(products),
        i18n,
        call(order),
        call(config)
    ])
}