import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Product from "components/Product";
import useI18n from "state/i18n";

const Container = styled.section`
  width: 100%;
  min-height: 25rem;
  overflow-y: scroll;
  padding: 1rem;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.h1``;

const Empty = styled.p`
  margin: 0;
  padding: 0mm;
  text-align: center;
`;

export const ProductsByCategories = ({ categories }) => {
  const [components, setComponents] = useState([]);
  const PAGESIZE = 10;

  const [page, setPage] = useState(null);
  const [list, setList] = useState([]);
  const loader = useRef(null);

  const [i18n] = useI18n();

  useEffect(() => {
    setList(components.slice(0, PAGESIZE));
    setPage(0);
  }, [components]);

  useEffect(() => {
    if (page === 0) return;

    const initialItem = page * PAGESIZE;
    const endingItem = initialItem + PAGESIZE;

    const newList = components.slice(initialItem, endingItem);

    const fulllist = [...list, ...newList];

    setList([...fulllist]);
  }, [page]);

  const nextPage = () => setPage(page + 1);

  useEffect(() => {
    const components = [];

    categories.forEach((category) => {

      const products = category.products
        .filter((p) => p.status === 'active')
        .sort((a, b) => (a.title > b.title ? 1 : -1));

      if (products.length === 0) return;

      components.push({
        type: "title",
        id: "category" + category.id,
        props: { children: category.title },
      });

      products.forEach((product) => {
        components.push({
          type: "product",
          id: category.id + "-product" + product.id,
          props: product,
        });
      });
    });

    setComponents(components);
  }, [categories]);

  const onScroll = () => {
    const elementTop = loader.current.getBoundingClientRect().top;
    const screenHeight = window.screen.height;
    const maxPage = Math.ceil(components.length / PAGESIZE);

    screenHeight > elementTop && page < maxPage && nextPage();
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [components, page]);

  return (
    <Container>
      {list.length === 0 ? <Empty>{i18n.tag("SEARCH_EMPTY")}</Empty> : list.map((component) =>
        component.type === "title " ?
          <Title key={component.id}>{component.props.children}</Title> :
          <Product {...component.props} key={component.id} />
      )}
      <div ref={loader}></div>
    </Container>
  );
};
