
export default {
    "CALL_SERVER_BUTTON": "SERVICIO",
    "ORDER_BUTTON": "VER ORDEN DE LA MESA",
    "SEARCH_PLACEHOLDER": "Buscar en Todos los Menús",
    "SEARCH_EMPTY": "No se encontró nada en tu búsqueda",
    "PROCESSING_TAB": "Orden Actual",
    "PROCESSED_TAB": "Ordenar de nuevo",
    "ORDER_TITLE": "ORDEN",
    "ADD_LABEL": "Agregar Producto",
    "SPECIAL_INSTRUCTIONS": "Con estas instruccion:",
    "WITHOUT_INGREDIENTS": "Sin:",
    "ACCEPT": "ACEPTAR",
    "CHECKIN_TITLE": "Su #MESA \n (checar manteleta)",
    "CHECKIN_INSTRUCTIONS": "Por favor, introduzca sus datos para reconocer su orden",
    "FIRST_NAME": "Nombre",
    "LAST_NAME": "Apellido",
    "REMEMBER": "Recordar mis órdenes pasadas",
    "REQUIRED": "* Campos requeridos",
    "START_ORDERING": "EMPEZAR A ORDENAR",
    "EMAIL": "Email",
    "NO_SPAM": "Prometemos no hacer spam",
    "MR": "Sr",
    "MRS": "Srta",
    "NEITHER": "Ninguno",
    "INGREDIENTS_INSTRUCTIONS": "Seleccionar ingredientes a remover",
    "COMMENTS_LABEL": "Instrucciones especiales",
    "COMMENTS": "Comentarios",
    "ADD_ORDER_BUTTON": "AGREGAR A LA WISH LIST",
    "SEND_ORDER_BUTTON": "ENVIAR ORDEN AL MESERO",
    "ALL_LABEL": "Menú completo",
    "SAVE": "GUARDAR",
    "EXPAND": "Expandir todos",
    "COLLAPSE": "Colapsar todos",
    "SERVER": "Mesero",
    "call": "MESERO NOTIFICADO",
    "comingsoon": "UN MOMENTO",
    "comingnow": "MESERO EN CAMINO",
    "order": "ORDEN RECIBIDA POR EL MESERO",
    "SEE MENU" : "VER MENÚ",
    "NOT AVAILABLE" :"No disponible",
    "SEE_ALL": "Todos los Menús",
    "SELECT_A_CATEGORY": "Selecciona un Menú",
    "SELECTIONS": "Selección",
    "HIDE_FILTERS": "Ocultar filtros",
    "SHOW_FILTERS": "Mostrar filtros",
    "FULL_MENU" : "Menú completo",
    "ADD_SELECTION": "Agregar a mi selección",
    "ORDER_DETAILS": "Tus selecciones",
    "MAIN_MENU": "Menú principal",
    "TUTORIAL_SELECT_TOPIC": "Elige",
    "TUTORIAL_SELECT_LABEL": "el vino, bebida o platillo de tu preferencia.",
    "TUTORIAL_ADD_TOPIC": "Presiona el botón ver +",
    "TUTORIAL_ADD_LABEL": "si estás interesado.",
    "TUTORIAL_REVIEW_TOPIC": "Revisa",
    "TUTORIAL_REVIEW_LABEL": "tus selecciones con el mesero para ordenar.",
    "TUTORIAL_TITLE": "Cómo ordenar",
    "TUTORIAL_BUTTON": "Ir a menús",
    "LANG_PAGE_TITLE": "¡Bienvenido!",
    "LANG_PAGE_LABEL": "Porfavor, selecciona el idioma",
    "GALLERY_VIEW": "Vista Galería",
    "LIST_VIEW": "Vista Lista",
    "CHANGE_VIEW": "Cambiar Vista",
    "EXPAND_ALL": "Expandir Todo",
    "COLLAPSE_ALL": "Colapsar Todo",
    "HOURS": "Horas",
    "CATEGORY_SELECT_TITLE": "Por favor, selecciona un menú",
    "CATEGORY_MESSAGE_PLACEHOLDER": "Breakfast weekend<br>Sábado a Lunes<br>10 am - 1 pm",
    "Not_Available_Today": "Hoy no disponible",
    "MAKE_YOUR_SELECTION": "Haz tu selección",
    "DINE_IN": "COMER EN QUINCE",
}
