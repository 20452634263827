import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getConfig } from 'resources/Api';
import Types from './actions';

function* fetchStartAsync() {
    try {
        const res = yield getConfig();
        yield put({ type: Types.FETCH_SUCCESS, payload: res });
    } catch (error) {
        console.error(error)
        yield put({ type: Types.FETCH_ERROR });
    }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

export default function* sagas() {
    yield all([
        call(fetchStart),
    ]);
}
