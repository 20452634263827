import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import SelectionsIcon from "resources/img/selections-icon.svg";
import { BLUE } from "resources/Colors";
import useI18n from "state/i18n";
import useOrder from "state/order";
import useProducts from "state/products";

const Container = styled.button`
  transition: width 0.2s cubic-bezier(.17,.67,.83,.67), height 0.2s cubic-bezier(.17,.67,.83,.67);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: calc(calc(100vw - 414px) / 2);
  width: 6rem;
  height: 4.5rem;
  background-color: ${BLUE};
  border-radius: 8px 0 0 0;
  z-index: 100;
  cursor: default;
  border: none;
  @media (max-width: 414px) {
    right: 0;
  }
  &.expanded {
    width: 7rem;
    height: 5.5rem;
  }
`;

const SelectionsButtonWrapper = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const SelectionsLabel = styled.span`
  margin-top: 4px;
  font-size: 0.8rem;
  font-weight: 600;
`;

const Counter = styled.span`
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  font-weight: 500;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  width: 2.2rem;
`;

const FloatButton = ({ headerLabel }) => {
  const [order] = useOrder();
  const [i18n] = useI18n();
  const [amount, setAmount] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [products] = useProducts();
  const first = useRef(true);

  const history = useHistory();
  const { pathname: currentPath } = useLocation();

  const params = {
    pathname: "/order",
    redirectTo: currentPath,
    headerLabel,
  };

  useEffect(() => {
    if (!order) return;
    const { variantRelations, products: productsMap } = products;
    setAmount(order.reduce((acc, curr) =>
      productsMap[variantRelations[curr.id]]?.status !== "active" ||
        !productsMap[variantRelations[curr.id]]?.variants?.find(v => +v.id === +curr.id)?.active ?
        acc :
        acc + curr.count
      , 0));
    if (first.current) {
      first.current = false;
      return;
    }
    setExpanded(true);
    setTimeout(() => { setExpanded(false) }, 500);
  }, [order, products]);

  return (
    <Container className={`${expanded ? 'expanded' : ''}`} onClick={() => history.push(params)}>
      <SelectionsButtonWrapper>
        {amount > 0 && <Counter>{`(${amount})`}</Counter>}
        <Wrapper>
          <Icon src={SelectionsIcon} alt="" />
          <SelectionsLabel>{i18n.tag("SELECTIONS")}</SelectionsLabel>
        </Wrapper>
      </SelectionsButtonWrapper>
    </Container>
  );
};

export default FloatButton;
