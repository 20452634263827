import React from "react";
import styled from "styled-components";
import { ReactComponent as BackArrow } from "resources/img/back-icon.svg";

const Header = styled.header`
  display: flex;
  align-items: center;
  font-weight: 100;
`;

const BackLabel = styled.span`
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
`;

const BackHeader = ({ label, handler }) => {
  return (
    <Header onClick={handler}>
      <BackArrow />
      <BackLabel>{label}</BackLabel>
    </Header>
  );
};

export default BackHeader;
