import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";
import Loader from "./components/Loader";
import Menu from "./pages/Menu";
import Order from "./pages/Order";
import Search from "./pages/Search";
import useProducts from "./state/products";
import { Transition } from "./styles/transitions/transitions";
import { ViewContextProvider } from "ViewProvider";
import { FiltersContextProvider } from "pages/Menu/FiltersProvider";
import Socket from "./Socket";
import ConfigDialog from "components/ConfigDialog";
import useConfig from "state/config";

const AppWrapper = styled.div`
  position: relative;
  width: 100vw;
  max-width: 414px;
  height: 100vh;
  background-color: white;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

function App() {
  const [products, productsDispatcher] = useProducts();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [, configDispatcher] = useConfig();

  useEffect(() => {
    setIsLoading(products.tree.length === 0);
  }, [products.tree]);

  useEffect(() => {
    productsDispatcher.fetchStart();
    configDispatcher.fetchStart();
    history.listen((location, action) => {
      if (action === 'PUSH') {
        window.scrollTo(0, 0);
      }
    });
  }, []);

  return (
        <FiltersContextProvider>
          <ViewContextProvider>
            <Socket />
            <ConfigDialog />
            <Route
              render={({ location }) => {
                return (
                  <Loader isLoading={isLoading}>
                    <AppWrapper>
                      <Transition
                        timeout={600}
                        classNames="page"
                        transitionKey={location.key}
                      >
                        <Switch location={location}>
                          <Route exact={true} path="/">
                            <Redirect to={"/menu"} />
                          </Route>
                          <Route path="/menu" component={Menu} />
                          <Route exact path="/order" component={Order} />
                          <Route exact path="/search" component={Search} />
                        </Switch>
                      </Transition>
                    </AppWrapper>
                  </Loader>
                );
              }}
            />
          </ViewContextProvider>
        </FiltersContextProvider>
  );
}

export default App;
