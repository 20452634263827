import ACTIONS from "./actions";
import { setOnLocalStorage, loadFromLocalStorage } from "../utils";

const initialState = loadFromLocalStorage("order_cart", []);

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ADD: {
      const order = [...state];

      for (let i = 0; i < state.length; i++) {
        const prod = state[i];
        if (+prod.id === +action.payload.id) {
          order[i] = !!action.payload.count ? { id: +prod.id, count: action.payload.count } : { id: +prod.id, count: prod.count + 1 };
          setOnLocalStorage("order_cart", order);
          return order;
        }
      }

      const newOrder = !!action.payload.count ? { id: +action.payload.id, count: action.payload.count } : { id: +action.payload.id, count: 1 };
      order.push(newOrder);
      setOnLocalStorage("order_cart", order);
      return order;
    }

    case ACTIONS.REMOVE: {
      const order = [...state];

      for (let i = 0; i < state.length; i++) {
        const prod = state[i];
        if (+prod.id === +action.payload) {
          if (prod.count === 1) {
            order.splice(i, 1);
          } else {
            order[i] = { id: prod.id, count: prod.count - 1 };
          }
          setOnLocalStorage("order_cart", order);
          return order;
        }
      }

      return state;
    }

    case ACTIONS.EMPTY: {
      localStorage.removeItem("order_cart");
      return [];
    }

    default:
      return state;
  }
};
