import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Lang from "../components/Lang";
import ClockAnimation from "resources/animations/clock_inverted.json";
import Lottie from "react-lottie-player";
import useI18n from "state/i18n";
import useConfig from "state/config";
import { BLUE } from "resources/Colors";
import SearchIcon from "resources/img/search-icon.svg";
import { useHistory, useLocation } from "react-router-dom";
import { IMAGES_URL } from "resources/constants";

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  align-items: center;
  height: 3.5rem;
  margin: 0 1rem;
`;

const LangWrapper = styled.div`
  display: flex;
  justify-self: start;
  align-items: center;
`;

const RightWrapper = styled.div`
  display: flex;
  justify-self: end;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-self: end;
  align-items: center;
`;

const InfoTitle = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  color: ${BLUE};
  margin-left: 5px;
`;

const Logo = styled.img`
  height: 3rem;
`;

const getLogo = (config) => {
  const label = 'restaurant-logo';
  if(!config[label] || !config[label].payload.img) return `${process.env.PUBLIC_URL}/logo.svg`;

  return `${IMAGES_URL}/${config[label].payload.img}`;
}

const InfoIcon = ({ isActive=false }) => {

  const first = useRef(true);
  const [playing, setPlaying] = useState(false);
  const [direction, setDirection] = useState(1);

  useEffect(()=>{
    if(first.current){
      first.current=false;
      return;
    }
    if(isActive){
      setDirection(1);
    } else {
      setDirection(-1);
    }
    setPlaying(true);
  }, [isActive]);

  return (
    <Lottie
        loop={false}
        animationData={ClockAnimation}
        play={playing}
        direction={direction}
        style={{
            width: "24px",
            height: "24px",
            margin: "unset",
        }} 
        onComplete={()=>{
          setPlaying(false);
        }}
    />
  );
};

const Search = styled.img`
  margin-left: 10px;
  width: 24px;
`;

const Topbar = ({ toggler }) => {
  const history = useHistory();
  const [config] = useConfig();
  const {pathname: currentPath} = useLocation() 
  const handleSearch = () => history.push({
    pathname: "/search",
    redirectTo: currentPath
  });

  const [i18n] = useI18n();
  const [isActive, setActive] = useState(false);

  const handleInfo = () => {
    setActive(!isActive);
    toggler();
  };

  return (
      <Container>
          <LangWrapper>
              <Lang />
          </LangWrapper>
          <Logo src={getLogo(config)} alt="" style={{ justifySelf: 'center' }} />
          <RightWrapper>
            {!!toggler && (
                <InfoWrapper onClick={handleInfo}>
                    <InfoIcon isActive={isActive} />
                    <InfoTitle>{i18n.tag('HOURS')}</InfoTitle>
                </InfoWrapper>
            )}
            <Search src={SearchIcon} onClick={handleSearch}/>
          </RightWrapper>
      </Container>
  );
};

export default Topbar;
