import React, { useMemo } from 'react';
import useConfig from 'state/config';
import useI18n from "state/i18n";
import styled from 'styled-components';

const Label = styled.div`
  margin-bottom: 0px;
  text-align: right;
  font-size: 11px;
  font-weight: 600;
  color: #E84961;
  text-transform: capitalize;
  background-color: white;
  padding: 0px;
  border-radius: 12px;

  white-space: nowrap;
  flex: 2;

  @media (min-width: 400px) { 
    font-size: 12px;
    padding: 3px 2px;
    padding-bottom: 0px;
  }
`;

const getTextFromConfig = (config, lang) => {
  const label = 'out-of-stock-message'
  if(!config[label] || !config[label].payload || !config[label].payload.text) return '';

  return !!lang ? config[label].payload.text.en : config[label].payload.text.es;
}

const Inactive86Label = () => {
  const [i18n] = useI18n();
  const [config] = useConfig();

  const text = useMemo(()=>getTextFromConfig(config, i18n.lang),[config, i18n.lang]);

  return (
    <Label
      dangerouslySetInnerHTML={{ __html: text ?? '' }}
    />
  )
}
export default Inactive86Label;
