import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import HomePage from "./pages/Home";
import ProductsPage from "./pages/Products";
import SelectCategory from "./pages/SelectCategory";
import { ExpandedContextProvider } from "./ExpandedProvider";

const Menu = () => {
  const { path } = useRouteMatch();

  return (
      <ExpandedContextProvider>
        <Route
          render={({ location }) => {
            return (
              <Switch location={location}>
                <Route exact={true} path={path} component={SelectCategory} />

                <Route
                  exact={true}
                  path={`${path}/:categoryId`}
                  component={HomePage}
                />

                <Route
                  exact
                  path={`${path}/:categoryId/:subcategoryId`}
                  component={ProductsPage}
                />

                <Route
                  exact
                  path={`${path}/:categoryId/:subcategoryId/:productId`}
                  component={ProductsPage}
                />
              </Switch>
            );
          }}
        />
      </ExpandedContextProvider>
  );
};

export default Menu;
