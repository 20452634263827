import { combineReducers } from 'redux';
import i18n from './i18n/reducer';
import order from './order/reducer';
import products from './products/reducer';
import config from './config/reducer';

const rootReducer = combineReducers({
    i18n,
    products,
    order,
    config
})

export default rootReducer;