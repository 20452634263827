import styled from "styled-components";

const Figure = styled.div`
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: 15px;
    height: 200%;

    position: absolute;
    top: -57%;
    left: 50%;

    transform: rotate(45deg);
    transform-origin: left;
    background-color: ${({ is86Inactive }) => is86Inactive ? 'rgba(232, 73, 97,.7)' : 'transparent'} ;
  }
`;
export default Figure;