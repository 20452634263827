import React from "react";
import AddIcon from "resources/img/Icon-add.svg";
import RemoveIcon from "resources/img/remove-icon.svg";
import Inactive86Label from "./components/Inactive86Label";
import {
  ProductCounter,
  SimpleImgContainer,
  Img,
  Price,
  Title,
  TitleContainer,
  SimpleCard,
  VariantContainer,
  VariantName,
  VariantPrice,
} from "./product-styles";
import { useMemo } from "react";

export const Product = ({
  title,
  handleSelection,
  isOrdered,
  amounts,
  variants,
  remove,
  is86Inactive,
  perVariant = false,
  variantId,
  config,
}) => {

  const amount = useMemo(() => {
    return perVariant ?
      amounts.find(variant => +variant.id === +variantId)?.count || 0 :
      amounts?.reduce((count, variantAmount) => count += +variantAmount.count, 0) || 0;
  }, [amounts]);

  const variant = useMemo(() => {
    return perVariant ?
      variants.find(variant => variant.id === variantId) :
      variants?.length ? variants[0] : null;
  }, [variantId, variants]);

  return (
    <SimpleCard isSelected={isOrdered}>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      {variants?.length < 2 && <Price>{config?.marketPrice ? 'MKT' : `$${variants[0].price}`}</Price>}
      {perVariant && variants.length > 1 && (
        <div style={{ marginTop: "5px", flex: 1 }}>
          <VariantContainer>
            <VariantName>{variant.title}</VariantName>
            <VariantPrice>{config?.marketPrice ? 'MKT' : `$${variant.price}`}</VariantPrice>
          </VariantContainer>
        </div>
      )}
      {
         !perVariant && variants?.length > 1 && (
          <div style={{ marginTop: "5px", marginBottom: "5px", flex: 1 }}>
            {
              variants.map((variant, index) => (
                <VariantContainer key={index}>
                  <VariantName>{variant.title}</VariantName>
                  <VariantPrice>{config?.marketPrice ? 'MKT' : `$${variant.price}`}</VariantPrice>
                </VariantContainer>
              ))
            }
          </div>
        )
      }
      {is86Inactive ? (
        <Inactive86Label/>
      ) : (
        <SimpleImgContainer>
          {isOrdered && !!amount && <ProductCounter>{amount}</ProductCounter>}
          <Img src={remove ? RemoveIcon : AddIcon} onClick={() => handleSelection(variant?.id, remove)}  />
        </SimpleImgContainer>
      )}
    </SimpleCard>
  );
};

export default Product;