import React from "react";
import styled from "styled-components";
import { BLUE, GRAY } from "resources/Colors";
import useI18n from "state/i18n";
import { useContext } from "react";
import { FiltersContext } from "pages/Menu/FiltersProvider";

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;
  margin: 0;
`;

const FilterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isSelected }) => (isSelected ? "white" : "black")};
  background-color: ${({ isSelected }) => (isSelected ? BLUE : GRAY)};
  border-radius: 6px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  height: 100%;
  border: none;
  &:focus {
    outline-color : ${BLUE}
  }
`;

const FilterLabel = styled.span`
  margin: 0.4rem 0.5rem;
  font-size: 1rem;
`;

const Filters = ({ tags, type, withSelectAll=true, categoryId }) => {
  const [i18n] = useI18n();
  const { tagsSelected, setTag, resetTags, includesTag } = useContext(FiltersContext);

  return (
    <ButtonsWrapper>
      {withSelectAll && <FilterButton
        onClick={() => resetTags(categoryId)}
        isSelected={!tagsSelected.get(categoryId) || tagsSelected.get(categoryId)?.length === 0}
        style={{ order: '0', WebkitOrder: '0' }}
      >
        <FilterLabel>{i18n.tag("FULL_MENU")}</FilterLabel>
      </FilterButton>}
        {tags.map((tag) => (
          <FilterButton
            onClick={() => setTag(categoryId, type, tag)}
            isSelected={includesTag(categoryId, tag)}
            key={tag.id}
            style={{ order: tag.config?.position, WebkitOrder: tag.config?.position }}
          >
            <FilterLabel>{tag.title}</FilterLabel>
          </FilterButton>
        ))}
    </ButtonsWrapper>
  );
};

export default Filters;
