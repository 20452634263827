import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/core/styles";
import Product from "components/Product";
import { ExpandedContext } from "pages/Menu/ExpandedProvider";
import React, { useContext } from "react";
import { LIGHT, LIGHT_GRAY } from "resources/Colors";
import { IMAGES_URL } from "resources/constants";
import Down from "resources/img/down-arrow-icon.svg";
import Up from "resources/img/up-arrow-icon.svg";
import styled from "styled-components";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding: 0 1rem;
`;

const ListWrapper = styled.div`
  display: block;
  padding: 0 1rem;
`;

const CardContainer = styled.div`
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${LIGHT_GRAY};
  display: grid;
  &::before {
    content: "";
    padding-bottom: 110%;
    display: block;
  }

  &::before,
  .space {
    grid-area: 1 / 1 / 2 / 2;
  }
`;

const CardElementsContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr minmax(2rem, auto);
`;

const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("${IMAGES_URL}/${({ img }) => img ? 'mini.' + img : 'noimage.svg'}");
  background-size: ${({ img }) => (img ? "cover" : "60%")};
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
`;

const CardFooter = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  padding: 0 11px;
  margin : 0.5rem 0;
  @media only screen and (max-width: 440px) {
    min-height: 35px;
  }
`;

const Title = styled.span`
  font-weight: bold;
  margin-right: 3px;
  @media only screen and (max-width: 440px) {
    font-size: 0.87rem;
  }
`;

const Counter = styled.span`
  font-weight: 600;
  color: #3b4445;
  @media only screen and (max-width: 440px) {
    font-size: 0.8rem;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Loading = withStyles(() => ({
  root: {
    position: "absolute",
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)",
    color: LIGHT,
    zIndex: 1,
  },
}))(CircularProgress);

const SubcategoryCard = ({ subcategory }) => (
  <>
    <ImageWrapper>
      <Loading />
      <CardImage img={subcategory.img} />
    </ImageWrapper>
    <CardFooter>
      <Title>{subcategory.title}</Title>
      <Counter>{`(${subcategory.productsLength})`}</Counter>
    </CardFooter>
  </>
);

const ExpandableContainer = styled.div`
  padding: 2px 0;
`;

const Header = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 20px 30px;
`;

const ExpandableTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-height: 55px;
`;

const ExpandableTitle = styled.span`
  flex: 1;
  font-size: 1.2rem;
  font-weight: 400;
  @media only screen and (max-width: 440px) {
    font-size: 1.3rem;
  }
`;

const ExpandableImage = styled.img`
  width: 55px;
  height: 55px;
  object-fit: cover;
`;

const ExpandableCount = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
`;

const Chevron = styled.img`
  width: 100%;
`;

const Separator = styled.div`
  width: 70%;
  border-bottom: 2px solid ${LIGHT_GRAY};
`;

const CollapseContainer = styled(Collapse)`
  margin-top: 10px;
`;

const Expandable = ({ id, title, count, img, children }) => {
  const [, toggleSubcategory, includesSubcategory] = useContext(ExpandedContext);

  const toggle = () => {
    toggleSubcategory(id);
  };

  const shouldBeOpen = includesSubcategory(id);

  return (
    <>
      <Separator style={{ marginLeft: shouldBeOpen ? '0' : '67px' }} />
      <ExpandableContainer>
        <Header onClick={toggle} style={{ marginBottom: shouldBeOpen ? '10px' : '0' }}>
          <ExpandableTitleContainer>
            {!shouldBeOpen && <ExpandableImage src={`${IMAGES_URL}/${img ? 'mini.' + img : 'noimage.svg'}`} />}
            <ExpandableTitle>{title}</ExpandableTitle>
            <ExpandableCount>{`(${count})`}</ExpandableCount>
          </ExpandableTitleContainer>
          <div />
          <Chevron src={shouldBeOpen ? Up : Down} />
        </Header>
        <CollapseContainer in={shouldBeOpen} timeout="auto" unmountOnExit>
          {children}
        </CollapseContainer>
      </ExpandableContainer>
    </>
  );
};

const Subcategories = ({
  subcategories,
  handleSubcategory,
  viewAsList = false,
}) => {

  return (
    <>
      {viewAsList ? (
        <ListWrapper>
          {subcategories.map(
            (subcategory) =>
              subcategory.productsLength > 0 && (
                <Expandable
                  key={subcategory.id}
                  id={subcategory.id}
                  title={subcategory.title}
                  count={subcategory.productsLength}
                  img={subcategory.img}
                >
                  {subcategory.products.map((product) =>
                  (
                    <Product
                      {...product}
                      key={product.id}
                    />
                  )
                  )}
                </Expandable>
              )
          )}
        </ListWrapper>
      ) : (
        <GridWrapper>
          {subcategories.map((subcategory, i) => (
            <>
              {subcategory.productsLength > 0 && (
                <CardContainer
                  onClick={() => handleSubcategory(subcategory)}
                  key={subcategory.id}
                >
                  <CardElementsContainer className="space">
                    <SubcategoryCard subcategory={subcategory} />
                  </CardElementsContainer>
                </CardContainer>
              )}
            </>
          ))}
        </GridWrapper>
      )}
    </>
  );
};

export default Subcategories;
