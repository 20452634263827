import React, { useMemo } from "react";
import AddIcon from "resources/img/Icon-add.svg";
import RemoveIcon from "resources/img/remove-icon.svg";
import Card from "components/Card";
import Inactive86Label from "./components/Inactive86Label";
import {
  Bottom,
  ProductCounter,
  ImgContainer,
  Img,
  Price,
  Tags,
  Title,
  Top,
  Footer,
  VariantContainer,
  VariantName,
  VariantPrice,
} from "./product-styles";

export const Product = ({
  title,
  handleCard,
  handleSelection,
  img,
  tags,
  description,
  isOrdered,
  amounts,
  remove,
  is86Inactive,
  variants,
  perVariant = false,
  variantId,
  config,
}) => {
  const tagsLabel = tags?.map((tag) => tag.title).join(" | ");

  const amount = useMemo(() => {
    return perVariant ?
      amounts.find(variant => +variant.id === +variantId)?.count || 0 :
      amounts?.reduce((count, variantAmount) => count += +variantAmount.count, 0) || 0;
  }, [amounts]);

  const variant = useMemo(() => {
    return perVariant ?
      variants.find(variant => variant.id === variantId) :
      variants?.length ? variants[0] : null;
  }, [variantId, variants]);

  return (
    <Card img={img} is86Inactive={is86Inactive} handler={handleCard} isSelected={isOrdered}>
      <Top onClick={handleCard}>
        <Title>{title}</Title>
        {variants?.length < 2 && <Price>{config?.marketPrice ? 'MKT' : `$${variants[0].price}`}</Price>}
      </Top>
      {perVariant && variants.length > 1 && (
        <div style={{ marginTop: "5px" }}>
          <VariantContainer>
            <VariantName>{variant.title}</VariantName>
            <VariantPrice>{config?.marketPrice ? 'MKT' : `$${variant.price}`}</VariantPrice>
          </VariantContainer>
        </div>
      )}
      {
        !perVariant && variants?.length > 1 && (
          <div style={{ marginTop: "5px", marginBottom: "5px" }}>
            {
              variants.map((variant, index) => (
                <VariantContainer key={index}>
                  <VariantName>{variant.title}</VariantName>
                  <VariantPrice>{config?.marketPrice ? 'MKT' : `$${variant.price}`}</VariantPrice>
                </VariantContainer>
              ))
            }
          </div>
        )
      }
      <Bottom onClick={handleCard}>
        <div
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Bottom>
      <Footer>
        <Tags is86Inactive={is86Inactive} onClick={handleCard}>{tagsLabel}</Tags>
        {is86Inactive ? (
          <Inactive86Label />
        ) : (
          <ImgContainer>
            {isOrdered && !!amount && <ProductCounter>{amount}</ProductCounter>}
            <Img src={remove ? RemoveIcon : AddIcon} onClick={() => handleSelection(variant?.id, remove)} />
          </ImgContainer>
        )}
      </Footer>
    </Card>
  );
};

export default Product;
