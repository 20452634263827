import ACTIONS from './actions';

class Dispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    add = (id, count) => this.dispatch({ type: ACTIONS.ADD, payload: {id, count} });

    remove = id => this.dispatch({ type: ACTIONS.REMOVE, payload: id });

    update = (index, item) => this.dispatch({ type: ACTIONS.UPDATE, payload: { index, item } })

    empty = () => this.dispatch({ type: ACTIONS.EMPTY })
}


export default Dispatcher;