export default {
    "CALL_SERVER_BUTTON": "CALL SERVER",
    "ORDER_BUTTON": "VIEW TABLE ORDER",
    "SEARCH_PLACEHOLDER": "Search in All Menus",
    "SEARCH_EMPTY": "Nothing found in your search",
    "PROCESSING_TAB": "Pending Order",
    "PROCESSED_TAB": "Order again",
    "ORDER_TITLE": "ORDER",
    "ADD_LABEL": "Add Item",
    "SPECIAL_INSTRUCTIONS": "With this special instructions:",
    "WITHOUT_INGREDIENTS": "Without:",
    "ACCEPT": "ACCEPT",
    "CHECKIN_TITLE": "Your TABLE# \n (see placement)",
    "CHECKIN_INSTRUCTIONS": "Please, provide your name to recognize your order",
    "FIRST_NAME": "First Name",
    "LAST_NAME": "Last Name",
    "REMEMBER": "Remember me and my past orders",
    "REQUIRED": "* Required fields",
    "START_ORDERING": "START ORDERING",
    "EMAIL": "Your email address",
    "NO_SPAM": "Promise no spam",
    "MR": "Mr",
    "MRS": "Mrs",
    "NEITHER": "Neither",
    "INGREDIENTS_INSTRUCTIONS": "See ingredients to remove",
    "COMMENTS_LABEL": "Special instructions",
    "COMMENTS": "Comments",
    "ADD_ORDER_BUTTON": "ADD TO WISH LIST",
    "SEND_ORDER_BUTTON": "SEND ORDER TO SERVER",
    "ALL_LABEL": "Full menu",
    "SAVE": "SAVE",
    "EXPAND": "Expand all",
    "COLLAPSE": "Collapse all",
    "SERVER": "Server",
    "call": "SERVER CALLED", 
    "comingsoon": "SERVER COMING SOON",
    "comingnow": "SERVER COMING NOW",
    "order": "ORDER RECEIVED BY WAITER",
    "SEE MENU" : "SEE MENU",
    "NOT AVAILABLE" :"Not available",
    "SEE_ALL": "All Menus",
    "SELECT_A_CATEGORY": "Select a Menu",
    "SELECTIONS": "Selections",
    "HIDE_FILTERS": "Hide filters",
    "SHOW_FILTERS": "Show filters",
    "FULL_MENU" : "Full menu",
    "ADD_SELECTION": "Add to my selections",
    "ORDER_DETAILS": "Your selections",
    "MAIN_MENU": "Main menu",
    "TUTORIAL_SELECT_TOPIC": "Find",
    "TUTORIAL_SELECT_LABEL": "your wine, drinks & meal of preference.",
    "TUTORIAL_ADD_TOPIC": "Push green +",
    "TUTORIAL_ADD_LABEL": "if interested.",
    "TUTORIAL_REVIEW_TOPIC": "Review",
    "TUTORIAL_REVIEW_LABEL": "your selections with server to decide final order.",
    "TUTORIAL_TITLE": "How to order",
    "TUTORIAL_BUTTON": "Go to Menus",
    "LANG_PAGE_TITLE": "Welcome!",
    "GALLERY_VIEW": "Gallery View",
    "LIST_VIEW": "List View",
    "CHANGE_VIEW": "Change View",
    "EXPAND_ALL": "Expand All",
    "COLLAPSE_ALL": "Collapse All",
    "HOURS": "Hours",
    "CATEGORY_SELECT_TITLE": "Please, select a menu",
    "CATEGORY_MESSAGE_PLACEHOLDER": "Breakfast weekend<br>Saturday to Monday<br>10 am - 1 pm",
    "Not_Available_Today": "Not Available Today",
    "MAKE_YOUR_SELECTION": "Make your selection",
    "DINE_IN": "DINE-IN @ QUINCE",
}
