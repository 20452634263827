import React from "react";
import useConfig from "state/config";
import styled from "styled-components";
import { BLUE, LIGHT_GRAY } from "../../../../../resources/Colors";
import { ICONS_URL } from "../../../../../resources/constants";

const ImageWrapper = styled.div`
  transition-duration: 0.5s;
  background-color: ${({ selected }) => (selected ? BLUE : LIGHT_GRAY)};
  ${({ selected }) => (selected ? "box-shadow: 0px 8px 24px #0332693D;" : "")}
  width: ${({selected}) => selected ? '4rem' : '3.8rem'};
  height: ${({selected}) => selected ? '4rem' : '3.8rem'};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  transition-duration: 0.5s;
  width: ${({selected}) => selected ? '2.7rem' : '2rem'};
  height: ${({selected}) => selected ? '2.7rem' : '2rem'};
`;

const CategoryWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 18px;
  }
`;

const Container = styled.ul`
  display: -webkit-box;
  overflow-x: scroll;
  margin: 0;
  padding: 0.5rem 0 0.5rem 1rem;
  list-style-type: none;
`;

const ButtonContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CategoryTitle = styled.span`
  opacity: 0.8;
  margin-top: 5px;
  overflow-wrap: break-word;
  font-size: 0.6rem;
  letter-spacing: 0.5px;
  font-weight: bold;
  text-align: center;
  width: 3.5rem;
  text-transform: uppercase;
`;

const Category = ({ category, isSelected }) => {
  const [config] = useConfig();
  const payload = config && config['selected-icon-set'] ? config['selected-icon-set']['payload'] : null;

  return (
    <ButtonContainer>
      <ImageWrapper selected={isSelected}>
        <Image
          src={payload ? `${ICONS_URL}/${payload['icon-set']}/${isSelected ? 'dark/' : ''}${category?.config?.icon}` : ''}
        />
      </ImageWrapper>
      <CategoryTitle isSelected={isSelected}>{category.title}</CategoryTitle>
    </ButtonContainer>
  );
};

export const Categories = ({ categories, handler, idSelected }) => {
  return (
    <Container>
      {categories.map((category) => (
        <CategoryWrapper onClick={() => handler(category)} key={category.id}>
          <Category
            category={category}
            isSelected={idSelected === category.id}
          />
        </CategoryWrapper>
      ))}
    </Container>
  );
};
