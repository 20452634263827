const isActive = (product) => product.status === 'active';

const getActiveProducts = (subcategory) =>
  subcategory.products.filter((product) => isActive(product));

const hasActiveProducts = (subcategory) => {
  return getActiveProducts(subcategory).length > 0
};

const sortByActiveProducts = (subcategory) => {
  return getActiveProducts(subcategory).sort((a, b) => {
    if (a.stockTotal > 0 && b.stockTotal > 0) return 0;
    if (a.stockTotal > 0 && b.stockTotal === 0) return -1;
    if (a.stockTotal === 0 && b.stockTotal > 0) return 1;
  })
}

const getSubcategoriesFiltered = (category) =>
  category.subcategories
    .map(
      (sc) =>
        hasActiveProducts(sc) && {
          ...sc,
          img: !!sc.img ? sc.img : "noimage.svg",
          products: sortByActiveProducts(sc),
          productsLength: getActiveProducts(sc).length,
        }
    )
    .filter((sc) => !!sc);

export default class Utils {
  constructor(state, dispatcher) {
    this.state = state;
    this.dispatcher = dispatcher;
  }

  find(order) {
    const productsFound = [];
    const alreadyFound = new Set();
    order.forEach(({ id }) => {
      if (this.state.variantRelations[id] && !alreadyFound.has(this.state.variantRelations[id])) {
        alreadyFound.add(this.state.variantRelations[id]);
        productsFound.push(this.state.products[this.state.variantRelations[id]]);
      }
    });

    return productsFound;
  }

  getCategory(categoryId) {
    return this.state.tree.find((category) => category.id === categoryId);
  }

  getCategoryWithActiveProducts(categoryId) {
    const category = this.state.tree.find(
      (category) => category.id === categoryId
    );
    return { ...category, products: sortByActiveProducts(category), subcategories: getSubcategoriesFiltered(category) };
  }

  getSubcategoriesWithActiveProducts(categoryId) {
    const category = this.getCategory(categoryId);
    return getSubcategoriesFiltered(category);
  }

  getCategoriesWithActiveProducts() {
    return this.state.tree.map((category) => ({
      ...category,
      subcategories: getSubcategoriesFiltered(category),
    }));
  }

  getTagsInCategory(categoryId) {
    const category = this.getCategory(categoryId);

    const collectTags = (productList) => {
      return productList.reduce((acc, product) => {
        if (isActive(product)) {
          product.tags.forEach((tag) => {
            if (!acc[tag.title]) {
              acc[tag.title] = tag;
            }
          });
        }
        return acc;
      }, {});
    };

    const tags = collectTags(category.products);

    category.subcategories.forEach(subcategory => {
      const subcategoryTags = collectTags(subcategory.products);
      for (const tag in subcategoryTags) {
        if (!tags[tag]) {
          tags[tag] = subcategoryTags[tag];
        }
      }
    });

    return Object.values(tags);
  }


  getDefaultTags(categoryId) {
    const category = this.getCategory(categoryId);

    const tags = [];
    this.getTagsInCategory(categoryId).forEach((tag) => {
      if (tag?.config?.selected === true) {
        tags.push(tag);
      }
    });
    let defaultTags = [];
    if (category.config?.withSelectAll === false) {
      defaultTags =
        category.config?.filterType === "unique"
          ? tags[0]
            ? [tags[0]]
            : []
          : tags;
    }
    return defaultTags;
  }
}
