import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BLUE } from '../../../../../resources/Colors';

const Container = styled.ul`
    display: -webkit-box;
    overflow-x: scroll;
    list-style-type: none;
    padding: 0;
    margin: 1rem 0 1rem 1rem;
`;

const SubcategoryWrapper = styled.li`
    margin-right: 1.5rem;
`;

const SubcategorySelectedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Subcategory = styled.p`
    font-size: 0.875rem;
    font-weight: ${({ isSelected }) => (isSelected ? '700' : '300')};
`;

const BlueBar = styled.div`
    width: 24px;
    height: 4px;
    border-radius: 4px;
    background-color: ${BLUE};
`;

const SubcategoriesSelector = ({ subcategories, selected, handleSelection }) => {
    useEffect(() => {
      setTimeout(()=>{
        document.getElementsByClassName('active-subcategory-selected-main')[0].scrollIntoView({block: "nearest", behavior: "smooth"});
      }, 400);
    }, []);

    return (
        <Container>
            {subcategories.map((subcategory) => (
                <SubcategoryWrapper onClick={() => handleSelection(subcategory)} key={subcategory.id}>
                    {selected.id === subcategory.id ? (
                        <SubcategorySelectedWrapper className="active-subcategory-selected-main">
                            <Subcategory isSelected={true}>{`${subcategory.title} (${subcategory.productsLength})  `}</Subcategory>
                            <BlueBar />
                        </SubcategorySelectedWrapper>
                    ) : (
                        <Subcategory>{`${subcategory.title} (${subcategory.productsLength})  `}</Subcategory>
                    )}
                </SubcategoryWrapper>
            ))}
        </Container>
    );
};

export default SubcategoriesSelector;
