import Arrow from "@material-ui/icons/KeyboardArrowUp";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import En from "resources/img/en-flag.svg";
import Es from "resources/img/es-flag.svg";
import useI18n from "state/i18n";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
`;

const Clickable = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
`;

const List = styled.ul`
  position: absolute;
  z-index: 10;
  margin: 0;
  top: 2rem;
  left: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 24px #0332693D;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
`;

const ItemList = styled.li`
  list-style-type: none;
  text-align: left;
  display: flex;
  position: relative;
  align-items: center;
  &:not(:last-child) {
    padding-bottom: 10px;
  }
`;

const LangSpan = styled.span`
  color: black;
  font-weight: 0.875rem;
  margin-left: 4px;
`;

const Flag = styled.img`
  width: 1.8rem;
`;

const Lang = ({className}) => {
  const [visible, setVisible] = useState(false);
  const [i18n, i18nDispatcher] = useI18n();

  const [selected, setSelected] = useState(i18n.lang);

  const langs = [
    [<Flag src={Es} />, "Es", "Español"],
    [<Flag src={En} />, "En", "English"],
  ];

  useEffect(() => {
    setSelected(i18n.lang);
  }, [i18n.lang]);

  const changeSelection = (index) => {
    setSelected(index);
    i18nDispatcher.translate(index);
  };

  const toggle = () => setVisible(!visible);

  return (
    <Container className={className ?? ''}>
      <Clickable onClick={() => toggle()}>
        {langs[selected][0]}
        <LangSpan>{langs[selected][1]}</LangSpan>
        <Content>
          {visible ? (
            <Arrow style={{ color: "black" }} />
          ) : (
            <Arrow style={{ color: "black", transform: "rotate(180deg)" }} />
          )}
        </Content>
      </Clickable>
      {visible && (
        <List onClick={() => toggle()}>
          {langs.map((lang, index) => {
            return (
              <ItemList key={index} onClick={() => changeSelection(index)}>
                {lang[0]}
                <LangSpan>{lang[2]}</LangSpan>
              </ItemList>
            );
          })}
        </List>
      )}
    </Container>
  );
};

export default Lang;
