import React from 'react'
import styled from "styled-components";
import { Spinner } from './Spinner';

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const Loader = ({isLoading, children}) => isLoading ? (
    <Center>
        <Spinner />
    </Center>
) : <> {children} </>


export default Loader
