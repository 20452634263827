import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getParamId, getRouteWithParams } from "resources/helpers";
import useProducts from "state/products";
import {
  SLIDE_IN_LEFT,
  SLIDE_OUT_LEFT,
  WithTransition,
} from "styles/transitions/transitions";
import { FiltersContext } from "pages/Menu/FiltersProvider";
import { ExpandedContext } from "pages/Menu/ExpandedProvider";
import HomeComponent from "./components/HomeComponent";
import { ViewContext } from "ViewProvider";
import useConfig from "state/config";

const HomePage = () => {
  const { tagsSelected, setTags } = useContext(FiltersContext);
  const [allOpened,,, openAll, collapseAll] = useContext(ExpandedContext);
  const {getView, setView} = useContext(ViewContext);
  const [products, , utils] = useProducts();
  const [subcategories, setSubcategories] = useState([]);
  const { categoryId: categoryPath } = useParams();
  const history = useHistory();
  const [, configDispatcher] = useConfig();
  const [rootProducts, setRootProducts] = useState([]);

  const getCategoryFromParams = () =>
    categoryPath === undefined
      ? products.tree[0]
      : products.tree.find(
          (category) => category.id === getParamId(categoryPath)
        );

  const [categorySelected, setCategorySelected] = useState(getCategoryFromParams());

  const loadSubcategories = (category) => {
    const subcategories = utils.getSubcategoriesWithActiveProducts(category.id);
    let subcategoriesFiltered = [];

    if (tagsSelected.get(category.id)?.length > 0) {
      subcategoriesFiltered = subcategories.map((subcategory) => ({
        ...subcategory,
        products: subcategory.products.filter((product) =>
          product.tags.some(
            (tag) => tagsSelected.get(category.id).some((tagSelected) => tagSelected.id === tag.id)
          )
        ),
      }));
    } else { 
      subcategoriesFiltered = subcategories;
    }

    return subcategoriesFiltered.map((sc) => ({
      ...sc,
      productsLength: sc.products.length,
    }));
  };

  useEffect(() => {
    configDispatcher.fetchStart();
    if (categoryPath === undefined) {
      window.history.replaceState(
        null,
        "",
        getRouteWithParams("/menu", products.tree[0])
      );
    }
  }, []);

  useEffect(() => {
    setSubcategories(loadSubcategories(categorySelected));
  }, [categorySelected, products.tree, tagsSelected]);

  useEffect(()=>{
    if(!tagsSelected.get(categorySelected.id) || tagsSelected.get(categorySelected.id).length === 0) {
      setTags(categorySelected.id, utils.getDefaultTags(categorySelected.id));
    }

    if(getView(categorySelected.id)===undefined){
      const view = categorySelected.config?.view;
      setView(categorySelected.id, view === 'list');
    }

    if(!!categorySelected.config?.expanded){
      openAll(utils.getSubcategoriesWithActiveProducts(categorySelected.id).map(sc=>sc.id))
    } else {
      (allOpened) && collapseAll();
    }
  }, [categorySelected]);

  useEffect(() => {
    const category = utils.getCategoryWithActiveProducts(categorySelected.id);
    setCategorySelected(category);
    setRootProducts(category.products);
  }, [products.tree]);

  useEffect(() => {
    if (tagsSelected.get(categorySelected.id)?.length > 0) {
      setRootProducts(categorySelected.products.filter((product) =>
        product.tags.some(
          (tag) => tagsSelected.get(categorySelected.id).some((tagSelected) => tagSelected.id === tag.id)
        )
      ));
    } else {
      setRootProducts(categorySelected.products);
    }
  }, [categorySelected, tagsSelected]);

  const handleCategory = (newCategory) => {

    setCategorySelected(newCategory);

    window.history.pushState(
      null,
      "",
      getRouteWithParams("/menu", newCategory)
    );
  };

  const handleSubcategory = (subcategory) =>
    history.push(getRouteWithParams("/menu", categorySelected, subcategory));

  return (
    <WithTransition enter={SLIDE_IN_LEFT} exit={SLIDE_OUT_LEFT}>
        <HomeComponent
          tree={products.tree}
          handleCategory={handleCategory}
          handleSubcategory={handleSubcategory}
          subcategories={subcategories}
          category={categorySelected}
          rootProducts={rootProducts}
        />
    </WithTransition>
  );
};

export default HomePage;
